import { FC, useEffect, useState } from 'react';

import { UpOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';

const ScrollToTopButton: FC = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setVisible(window.scrollY > 300); // Show button after scrolling 300px
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <FloatButton
      type="primary"
      icon={<UpOutlined style={{ color: '#fff' }} />}
      onClick={scrollToTop}
      style={{ display: visible ? 'block' : 'none', backgroundColor: '#8E53E8' }}
    />
  );
};

export default ScrollToTopButton;

import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PhoneInput from 'react-phone-input-2';
import mt from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Link, useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { LANDING_ROUTE, NOTIFICATION_ROUTE } from 'routes/user-management/list';
import { setTokenCookies } from 'tools/cookieTools';
import { t } from 'tools/i18n';
import { Button, Checkbox, Form, Input, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import {
  useLazyLoginQuery,
  useRegisterMutation,
} from 'services/user-management/userManagementApiService';

import { EMAIL_PLACEHOLDER, PASSWORD_PLACEHOLDER } from 'constants/core-constants';
import { CORE_URLS } from 'constants/coreUrls';
import { RegisterRequestProps } from 'types/user-management/auth';

import { REGISTER_FORM_ENUM, prepareRegisterFormData } from '../register.utils';
import s from './RegisterForm.module.scss';

const digitPattern = /\d/;
const uppercasePattern = /[A-Z]/;

interface RegisterFormProps {
  inviteId?: string;
}

const RegisterForm: FC<RegisterFormProps> = ({ inviteId }) => {
  const [registration, { isLoading }] = useRegisterMutation();
  const [getUser, { isLoading: loginIsLoading }] = useLazyLoginQuery();
  const [form] = Form.useForm();
  const password = Form.useWatch(REGISTER_FORM_ENUM.PASSWORD, form);
  const [blurPassword, setBlurPassword] = useState(false);
  const [isPasswordDirty, setIsPasswordDirty] = useState(false);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const passwordValidation = useMemo(() => {
    return {
      length: password?.length >= 6,
      number: digitPattern.test(password),
      uppercase: uppercasePattern.test(password),
    };
  }, [password]);

  const handleSubmit = useCallback(
    async (data: RegisterRequestProps) => {
      const result = prepareRegisterFormData(data);
      if (executeRecaptcha) {
        const token = await executeRecaptcha('register');
        const res = await registration({ ...result, captchaResponse: token });

        if ('data' in res) {
          const userRes = await getUser({
            email: data[REGISTER_FORM_ENUM.EMAIL],
            password: data[REGISTER_FORM_ENUM.PASSWORD],
            expiresInMinutes: 21600,
            refreshTokenExpiresInMinutes: 21600,
          });

          if ('data' in userRes) {
            setTokenCookies(userRes?.data?.accessToken, userRes?.data?.refreshToken);
            if (inviteId) {
              navigate(NOTIFICATION_ROUTE);
            } else {
              navigate(LANDING_ROUTE);
            }
          }
        }
      }
    },
    [executeRecaptcha, registration, getUser, inviteId, navigate],
  );

  useEffect(() => {
    if (password?.length) {
      setIsPasswordDirty(true);
    }
  }, [password]);

  const invalidPasswordField = {
    length: (isPasswordDirty || blurPassword) && !passwordValidation.length,
    number: (isPasswordDirty || blurPassword) && !passwordValidation.number,
    uppercase: (isPasswordDirty || blurPassword) && !passwordValidation.uppercase,
  };

  return (
    <div className={s.wrapper}>
      <Form
        className={s.form}
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
        onFinishFailed={() => setBlurPassword(true)}
        onValuesChange={(changedValues) => console.log(changedValues)}>
        <Link to={DASHBOARD_ROUTE} className={s.backLink}>
          <ArrowLeftOutlined /> BACK
        </Link>
        <Typography type="main" className={s.text}>
          Sign Up
        </Typography>

        <div className={s.row}>
          <Form.Item
            label={t('auth_register_first_name')}
            name={REGISTER_FORM_ENUM.FIRST_NAME}
            rules={[formRules.required]}
            fullWidth>
            <Input
              name={REGISTER_FORM_ENUM.FIRST_NAME}
              autoComplete="off"
              size="large"
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item
            label={t('auth_register_last_name')}
            name={REGISTER_FORM_ENUM.SECOND_NAME}
            rules={[formRules.required]}
            fullWidth>
            <Input
              name={REGISTER_FORM_ENUM.SECOND_NAME}
              autoComplete="off"
              size="large"
              placeholder="Last Name"
            />
          </Form.Item>
        </div>
        <div className={s.row}>
          <Form.Item
            label={t('common_email')}
            name={REGISTER_FORM_ENUM.EMAIL}
            validateFirst={true}
            rules={[formRules.required, formRules.email]}
            fullWidth>
            <Input
              autoComplete="off"
              name={REGISTER_FORM_ENUM.EMAIL}
              size="large"
              placeholder={EMAIL_PLACEHOLDER}
              fullWidth
            />
          </Form.Item>
        </div>
        <Form.Item
          className={s.phoneContainer}
          label={t('auth_register_phone')}
          name={REGISTER_FORM_ENUM.PHONE}
          rules={[formRules.required]}>
          <PhoneInput
            containerClass={s.phoneContainer}
            inputClass={s.phone}
            buttonClass={s.phoneButton}
            regions="europe"
            preferredCountries={['mt']}
            country="mt"
            localization={mt}
            inputProps={{ name: REGISTER_FORM_ENUM.PHONE }}
          />
        </Form.Item>

        <div className={s.block}>
          <div className={s.row}>
            <Form.Item
              label={t('common_password')}
              fullWidth
              name={REGISTER_FORM_ENUM.PASSWORD}
              rules={[formRules.password]}>
              <Input.Password
                name={REGISTER_FORM_ENUM.PASSWORD}
                size="large"
                type="password"
                autoComplete="off"
                placeholder={PASSWORD_PLACEHOLDER}
                // onFocus={() => setBlurPassword(false)}
                onBlur={() => setBlurPassword(true)}
              />
            </Form.Item>
          </div>
          <div className={s.row} style={{ marginTop: 12 }}>
            <div
              className={cn(
                s.hint,
                invalidPasswordField.length && s.invalid,
                isPasswordDirty && !invalidPasswordField.length && s.valid,
              )}>
              6 characters
            </div>
            <div
              className={cn(
                s.hint,
                invalidPasswordField.uppercase && s.invalid,
                isPasswordDirty && !invalidPasswordField.uppercase && s.valid,
              )}>
              Uppercase
            </div>
            <div
              className={cn(
                s.hint,
                invalidPasswordField.number && s.invalid,
                isPasswordDirty && !invalidPasswordField.number && s.valid,
              )}>
              Number
            </div>
          </div>
          <div className={s.row}>
            <Form.Item
              label={t('common_confirm_password')}
              fullWidth
              name={REGISTER_FORM_ENUM.CONFIRM_PASSWORD}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The confirm password does not match!'));
                  },
                }),
              ]}>
              <Input.Password
                name={REGISTER_FORM_ENUM.CONFIRM_PASSWORD}
                size="large"
                autoComplete="off"
                placeholder={PASSWORD_PLACEHOLDER}
              />
            </Form.Item>
          </div>
        </div>

        <div className={s.checkboxes}>
          <Form.Item name={REGISTER_FORM_ENUM.PROMOTIONS}>
            <Checkbox style={{ fontSize: 18 }}>Receive news and updates from OnThatStreet</Checkbox>
          </Form.Item>
        </div>

        <Button htmlType="submit" fullWidth size="large" isLoading={isLoading || loginIsLoading}>
          {t('auth_sing_up')}
        </Button>

        <div className={s.privacy}>
          By continuing you agree
          <a href={CORE_URLS.TERMS_OF_SERVICE} target="_blank" className={s.termsLink}>
            Terms of Service
          </a>
          and
          <a href={CORE_URLS.PRIVACY_POLICY} target="_blank" className={s.termsLink}>
            Privacy Policy
          </a>
          .
        </div>
      </Form>
    </div>
  );
};

export default RegisterForm;

import { FC, useCallback, useEffect, useState } from 'react';

import { Button, Radio } from 'antd';
import PhoneInput from 'react-phone-input-2';
import mt from 'react-phone-input-2';
import { Form, Input, Label, Typography, message } from 'ui';
import { formRules } from 'utils/formRules';

import { ProfileSelector } from 'selectors/userSlice.selectors';
import { useEditProfileMutation } from 'services/user-management/userManagementApiService';
import { setProfile } from 'slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { CONTACT_VISIBILITY, USER_TYPE_ENUM } from 'types/core/userTypes';

import s from './UserForm.module.scss';

const UserForm: FC = () => {
  const [userForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const [editProfile, { isLoading }] = useEditProfileMutation();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const profile = useAppSelector(ProfileSelector);

  const handleChange = () => {
    setIsDirty(true);
  };

  const handleSubmit = useCallback(
    async (data) => {
      if (data['contactDetailsPrivate'] === CONTACT_VISIBILITY.PRIVATE) {
        data['contactDetailsPrivate'] = true;
      } else {
        data['contactDetailsPrivate'] = false;
      }
      const res = await editProfile(data);

      if ('data' in res) {
        message.success('User profile successfully updated!');
        dispatch(setProfile(res.data));
      }
    },
    [dispatch, editProfile],
  );

  useEffect(() => {
    if (profile?.userType) {
      userForm.setFieldValue('userType', profile.userType);
    }
    if (profile?.firstName) {
      userForm.setFieldValue('firstName', profile.firstName);
    }
    if (profile?.lastName) {
      userForm.setFieldValue('lastName', profile.lastName);
    }
    if (profile?.propertiesManaged) {
      userForm.setFieldValue('propertiesManaged', profile.propertiesManaged);
    }

    if (profile?.contactDetailsPrivate === false) {
      userForm.setFieldValue('contactDetailsPrivate', CONTACT_VISIBILITY.PUBLIC);
    } else {
      userForm.setFieldValue('contactDetailsPrivate', CONTACT_VISIBILITY.PRIVATE);
    }

    if (profile?.mobileNumber) {
      userForm.setFieldValue('mobileNumber', profile.mobileNumber);
    }
  }, [profile, userForm]);

  return (
    <div className={s.wrapper}>
      <Form
        className={s.form}
        form={userForm}
        onFinish={handleSubmit}
        onValuesChange={handleChange}>
        <Form.Item name="userType" initialValue={USER_TYPE_ENUM.USER}>
          <Radio.Group
            className={s.filterSwitch}
            options={[
              { label: 'OWNER', value: USER_TYPE_ENUM.USER },
              { label: 'AGENT', value: USER_TYPE_ENUM.AGENT },
              { label: 'DEVELOPER', value: USER_TYPE_ENUM.DEVELOPER },
            ]}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <div>
          <Label>Name</Label>
          <Form.Item name="firstName" rules={[formRules.required]}>
            <Input style={{ backgroundColor: 'white' }} name="firstName" />
          </Form.Item>
        </div>
        <div>
          <Label>Surname</Label>
          <Form.Item name="lastName" rules={[formRules.required]}>
            <Input style={{ backgroundColor: 'white' }} name="lastName" />
          </Form.Item>
        </div>
        <div>
          <Label>How many properties do you own or manage?</Label>
          <Form.Item name="propertiesManaged">
            <Input style={{ backgroundColor: 'white' }} name="propertiesManaged" />
          </Form.Item>
        </div>
        <Typography type="h5" weight={700}>
          Contact details
        </Typography>
        <Typography type="small" color="grey">
          Phone numbers are only shown to logged in users, and when made private people can message
          you via Onthatstreet.
        </Typography>
        <div>
          <Form.Item name="contactDetailsPrivate">
            <Radio.Group
              className={s.filterSwitch}
              options={[
                { label: 'PUBLIC', value: CONTACT_VISIBILITY.PUBLIC },
                { label: 'PRIVATE', value: CONTACT_VISIBILITY.PRIVATE },
              ]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </div>
        <div>
          <Label>Mobile number</Label>
          <Form.Item name="mobileNumber">
            <PhoneInput
              containerClass={s.phoneContainer}
              inputClass={s.phone}
              buttonClass={s.phoneButton}
              regions="europe"
              preferredCountries={['mt']}
              country="mt"
              localization={mt}
            />
          </Form.Item>
        </div>

        <div className={s.Submit}>
          <Button
            disabled={!isDirty}
            loading={isLoading}
            style={{ width: 100, height: 36, marginTop: 16 }}
            type="primary"
            htmlType="submit">
            SAVE
          </Button>
        </div>
      </Form>
    </div>
  );
};
export default UserForm;

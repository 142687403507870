import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { SyncOutlined } from '@ant-design/icons';
import { Button, Dropdown, Empty, MenuProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import cn from 'classnames';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Map, {
  FullscreenControl,
  GeolocateControl,
  MapRef,
  Marker,
  NavigationControl,
  ScaleControl,
} from 'react-map-gl';
import { useClickOutside } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Form, Input, Select, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { getVicinity } from '../createDevelopment.utils';
// import { formRules } from 'utils/formRules';
import s from './DevLocationVenue.module.scss';
import { PrepareDevLocationVenueResult, prepareDevLocationVenue } from './devLocationVenue.utils';

const MAPBOX_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN;

const maltaBounds: [number, number, number, number] = [14.18, 35.79, 14.62, 36.12];

interface DevLocationVenueProps {
  locationData: PrepareDevLocationVenueResult | undefined;
  setLocationData: Dispatch<SetStateAction<PrepareDevLocationVenueResult | undefined>>;
}

const PropertyLocationVenue: FC<DevLocationVenueProps> = ({ locationData, setLocationData }) => {
  const mapRef = useRef<MapRef | null>(null);
  const [form] = useForm();
  const ref = useRef(null);
  const [openLocationDropdown, setOpenLocationDropdown] = useState<boolean>(false);
  const [searchIsDirty, setSearchIsDirty] = useState<boolean>(false);
  const [markerPosition, setMarkerPosition] = useState({
    longitude: locationData?.latLng?.lng ? locationData?.latLng?.lng : 14.5146,
    latitude: locationData?.latLng?.lat ? locationData?.latLng?.lat : 35.8989,
  });

  // const selectedCountry = Form.useWatch(['address', 'country'], form);

  // const [userInitCountry, setUserInitCountry] = useState<string>('');
  const [dragged, setDragged] = useState<boolean>(false);
  // const { data: currencies } = useGetAvailableCurrenciesQuery();

  // const [countries, setCountries] = useState<
  //   { name: string; flag: string; code?: string; latlng: string[] }[]
  // >([]);
  // const selectedCountryCode = countries?.find((country) => country.name === 'Malta')?.code;

  const [showMapStyle, setShowMapStyle] = useState('mapbox://styles/mapbox/streets-v12');

  const toggleMapStyle = () => {
    // Toggle between satellite and street view styles
    const newMapStyle =
      showMapStyle === 'mapbox://styles/mapbox/streets-v12'
        ? 'mapbox://styles/mapbox/satellite-streets-v12'
        : 'mapbox://styles/mapbox/streets-v12';

    setShowMapStyle(newMapStyle);
  };

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const handleSelectAddress = useCallback(
    (id: string, name: string) => {
      placesService?.getDetails({ placeId: id }, (placeDetails) => {
        const lat = placeDetails.geometry.location.lat();
        const lng = placeDetails.geometry.location.lng();

        setMarkerPosition({ longitude: lng, latitude: lat });

        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [lng, lat],
            zoom: 16,
            duration: 2000,
            essential: true,
          });
        }
        form?.setFieldValue(['address', 'fullAddress'], name);
        setLocationData(prepareDevLocationVenue(placeDetails, name));
        // if (onChangeForm) {
        //   onChangeForm(
        //     { address: preparePropertyLocationVenue(placeDetails, name) },
        //     form?.getFieldsValue(),
        //   );
        // }
      });
      setOpenLocationDropdown(false);
      setSearchIsDirty(false);
    },
    [form, placesService, setLocationData],
  );

  // const getUserCountry = useCallback(async (ip) => {
  //   const res = await fetch(`https://api.iplocation.net/?cmd=ip-country&ip=${ip}`);

  //   const data: {
  //     country_code2: string;
  //     country_name: string;
  //     ip: string;
  //     ip_number: string;
  //     ip_version: number;
  //     isp: string;
  //     response_code: string;
  //     response_message: string;
  //   } = await res.json();

  //   if (data?.country_name) {
  //     setUserInitCountry(data.country_name);
  //   }
  // }, []);

  // const getCountries = useCallback(async () => {
  //   const responseCountries = await fetch('https://restcountries.com/v3.1/all');
  //   const data = await responseCountries.json();
  //   fetch('https://api64.ipify.org?format=json')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const ipAddress = data.ip;
  //       if (ipAddress) {
  //         getUserCountry(ipAddress);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });

  //   setCountries(
  //     data
  //       ?.map((item) => ({
  //         name: item.name.common,
  //         flag: item.flag,
  //         code: item?.altSpellings[0],
  //         latlng: item?.capitalInfo?.latlng,
  //       }))
  //       .sort((a, b) => {
  //         const nameA = a.name.toLowerCase();
  //         const nameB = b.name.toLowerCase();

  //         if (nameA < nameB) {
  //           return -1;
  //         }
  //         if (nameA > nameB) {
  //           return 1;
  //         }
  //         return 0;
  //       }),
  //   );
  // }, [getUserCountry]);

  useClickOutside({
    ref,
    handler: () => {
      if (openLocationDropdown) {
        setOpenLocationDropdown(false);
        setSearchIsDirty(false);
      }
    },
  });

  const handleMarkerDragEnd = async (event: any) => {
    const { lngLat } = event;

    try {
      setMarkerPosition({
        latitude: lngLat.lat,
        longitude: lngLat.lng,
      });
      const vicinityResult = await getVicinity(lngLat.lat, lngLat.lng);

      if (vicinityResult) {
        getPlacePredictions({
          input: vicinityResult,
          componentRestrictions: {
            country:
              // selectedCountryCode?.toLowerCase()  ||
              'MT',
          },
        });
        setDragged(true);
        setSearchIsDirty(true);

        if (!vicinityResult) {
          setSearchIsDirty(false);
        }
      } else {
        console.warn('No vicinity result found.');
        setSearchIsDirty(false);
      }
    } catch (error) {
      console.error('Error in handleMarkerDragEnd:', error);
    }
  };

  useEffect(() => {
    if (placePredictions.length > 0 && dragged && !isPlacePredictionsLoading) {
      placesService?.getDetails({ placeId: placePredictions[0]?.place_id }, () => {
        if (mapRef.current) {
          mapRef.current.flyTo({
            center: [markerPosition?.longitude, markerPosition?.latitude],
            zoom: 16,
            duration: 2000,
            essential: true,
          });
        }

        form?.setFieldValue(['address', 'fullAddress'], placePredictions[0]?.description);
        setLocationData({
          latLng: { lat: markerPosition?.latitude, lng: markerPosition?.longitude },
        });
        // if (onChangeForm) {
        //   onChangeForm(
        //     {
        //       address: preparePropertyLocationVenue(placeDetails, placePredictions[0]?.description),
        //     },
        //     form?.getFieldsValue(),
        //   );
        // }
      });

      setOpenLocationDropdown(false);
      setSearchIsDirty(false);
      setDragged(false);
    }
  }, [
    placePredictions,
    dragged,
    form,
    isPlacePredictionsLoading,
    placesService,
    markerPosition,
    setLocationData,
  ]);

  const placesList: MenuProps['items'] = useMemo(() => {
    const result = placePredictions?.map((place) => ({
      label: (
        <div
          onClick={() => {
            handleSelectAddress(place?.place_id, place?.description);
          }}
          onTouchStart={() => handleSelectAddress(place?.place_id, place?.description)}
          className={s.item}
          onKeyDown={() => {}}
          role="button" // Helps accessibility
          tabIndex={0}>
          {place.description}
        </div>
      ),
      key: place.place_id,
    }));

    if (!result.length && searchIsDirty && !isPlacePredictionsLoading) {
      return [
        {
          label: (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>{t('event_create_form_no_results')}</span>}></Empty>
          ),
          key: 0,
        },
      ];
    } else {
      return result;
    }
  }, [handleSelectAddress, isPlacePredictionsLoading, placePredictions, searchIsDirty]);

  const handleChangeInput = (e) => {
    getPlacePredictions({
      input: e.target.value,
      componentRestrictions: { country: 'mt' },
    });
    setSearchIsDirty(true);
    if (e.target.value === '') {
      setSearchIsDirty(false);
    }
  };

  useEffect(() => {
    // const location = activeEditingEvent?.basicInfo?.location;
    // // if (location?.fullAddress) {
    // //   setVenueValue(activeEditingEvent?.basicInfo?.location?.fullAddress || '');
    // // }
    // if (location?.fullAddress) {
    //   form?.setFieldValue(['location', 'fullAddress'], location?.fullAddress);
    // }

    // if (location?.streetAddress) {
    //   form?.setFieldValue(['location', 'streetAddress'], location?.streetAddress);
    // }

    // if (location?.town) {
    //   form?.setFieldValue(['location', 'town'], location?.town);
    // }

    // if (location?.postCode) {
    //   form?.setFieldValue(['location', 'postCode'], location?.postCode);
    // }

    // if (location?.country) {
    //   form?.setFieldValue(['location', 'country'], { value: location?.country });
    // } else {
    form?.setFieldValue(['address', 'country'], 'Malta');
    // }
  }, [
    form,
    // userInitCountry
  ]);

  // useEffect(() => {
  //   getCountries();
  // }, [getCountries]);

  // const userCountry = countries.find((country) => country.name === selectedCountry?.value);

  return (
    <div>
      {/*{isCustomAddress && <div>custom address</div>}*/}
      <div>
        <div className={s.infoLocation}>
          <div className={s.block}>
            <div style={{ width: '100%' }}>
              <Typography type="small" className={cn(s.label)}>
                Country
              </Typography>
              <Form.Item
                name={['address', 'country']}
                // rules={[formRules.required]}
                fullWidth
                initialValue="Malta">
                <Select
                  disabled
                  // options={countries?.map((item) => ({
                  //   value: item.name,
                  //   label: `${item.flag} ${item.name}`,
                  // }))}
                  options={[{ value: 'mt', label: 'Malta' }]}
                  showSearch
                  filterOption={(input, option) =>
                    String(option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  fullWidth
                />
              </Form.Item>
            </div>
            <div style={{ width: '100%' }}>
              <Typography type="small" className={cn(s.label, s.required)}>
                Street
              </Typography>
              <div>
                <Form.Item name={['address', 'street']} rules={[formRules.required]}>
                  <Input
                    autoComplete="off"
                    name="street"
                    style={{ backgroundColor: 'white', width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={s.block}>
            <div style={{ width: '100%' }}>
              <Typography type="small" className={cn(s.label, s.required)}>
                {t('common_city')}
              </Typography>

              <div>
                <Form.Item name={['address', 'town']} rules={[formRules.required]}>
                  <Input
                    autoComplete="off"
                    name="town"
                    style={{ backgroundColor: 'white', width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <Typography type="small" className={cn(s.label)}>
                {t('common_postcode')}
              </Typography>
              <div>
                <Form.Item name={['address', 'postcode']}>
                  <Input
                    autoComplete="off"
                    name="postcode"
                    style={{ backgroundColor: 'white', width: '100%' }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/*<div>*/}
          {/*  <Typography type="small" className={cn(s.label)}>*/}
          {/*    {t('common_country')}*/}
          {/*  </Typography>*/}
          {/*  <Form.Item name={['location', 'country']} fullWidth initialValue="Malta">*/}
          {/*    <Input name="street" fullWidth />*/}
          {/*  </Form.Item>*/}
          {/*</div>*/}
        </div>

        <div ref={ref} className={s.wrapper} style={{ marginBottom: 16 }}>
          <Typography type="small" className={cn(s.label)}>
            Search for your street. Try English & Maltese, also try with the article for example
            “il-Fortizza”, or “triq il-“ instead of just Fortizza.” If you cant find it locate it on
            the map by zooming in and dragging the purple marker.
          </Typography>

          <div className={s.location}>
            <Dropdown
              menu={{ items: placesList }}
              open={!!placesList.length && openLocationDropdown}>
              <Form.Item name={['address', 'fullAddress']} fullWidth>
                <Input
                  autoComplete="new-password"
                  name="test"
                  style={{ backgroundColor: 'white' }}
                  onChange={handleChangeInput}
                  onFocus={() => setOpenLocationDropdown(true)}
                  icon="search"
                  isLoading={isPlacePredictionsLoading}
                />
              </Form.Item>
            </Dropdown>
          </div>
        </div>

        <div className={s.infoLocation}>
          <div className={s.mapWrapper}>
            <Button className={s.changeButton} onClick={toggleMapStyle}>
              <SyncOutlined />
            </Button>
            <Map
              maxPitch={0}
              pitch={0}
              dragRotate={false}
              maxBounds={maltaBounds}
              ref={mapRef}
              initialViewState={{
                longitude: locationData?.latLng?.lng ? locationData?.latLng?.lng : 14.5146, // Center the map around Malta's coordinates
                latitude: locationData?.latLng?.lat ? locationData?.latLng?.lat : 35.8989,
                zoom: 12,
                bearing: 0,
                pitch: 0,
              }}
              mapStyle={showMapStyle}
              mapboxAccessToken={MAPBOX_TOKEN}>
              <GeolocateControl position="bottom-right" />
              <FullscreenControl position="bottom-right" />
              <NavigationControl position="bottom-right" />
              <ScaleControl />

              <Marker
                longitude={markerPosition?.longitude}
                latitude={markerPosition?.latitude}
                draggable
                onDragEnd={handleMarkerDragEnd}
                // longitude={14.5146} // Center the map around Malta's coordinates
                // latitude={35.8989}

                anchor="center"></Marker>
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyLocationVenue;

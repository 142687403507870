import { FC, useCallback, useEffect } from 'react';

import MapComponent from 'ui/map/Map';

import {
  developmentListSelector,
  filteredPropertyListSelector,
  termsSelector,
} from 'selectors/propertySlice.selector';
import { useGetPropertiesMutation } from 'services/properties/propertyApiService';
import { setFilteredPropertyList, setInitialView, setTerms } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import s from './Landing.module.scss';

const Landing: FC = () => {
  const dispatch = useAppDispatch();

  const [getProperties] = useGetPropertiesMutation();
  const filteredPropertyList = useAppSelector(filteredPropertyListSelector);
  const developments = useAppSelector(developmentListSelector);
  const terms = useAppSelector(termsSelector);

  useEffect(() => {
    dispatch(
      setTerms({
        rentOption: 'rent_sale',
        apartOption: ['all'],
        bedroomCount: -1,
        price: undefined,
        searchName: undefined,
      }),
    );
    dispatch(setInitialView(undefined));
  }, [dispatch]);

  // useEffect(() => {
  //   const criteria = {
  //     pageNumber: 1,
  //     pageSize: 100,
  //   };
  //   getProperties(criteria);
  // }, [getProperties]);

  const handleGetPropertyList = useCallback(
    async (criteria) => {
      try {
        const res = await getProperties(criteria);
        if ('data' in res) {
          dispatch(setFilteredPropertyList(res?.data?.properties));
        }
      } catch (error) {
        console.error('Error fetching properties:', error);
      }
    },
    [getProperties, dispatch],
  );

  useEffect(() => {
    if (terms) {
      const criteria = {
        pageNumber: 1,
        pageSize: 100,
        filters: {
          numberOfBedrooms: terms.bedroomCount === -1 ? undefined : terms.bedroomCount,
          town: terms.searchName,
          priceFrom: terms.moreFilters?.from,
          priceTo: terms.moreFilters?.to,
          propertyTypes: terms.apartOption?.includes('all') ? undefined : terms.apartOption,
          postingType: terms.rentOption === 'rent_sale' ? null : terms.rentOption,
        },
      };

      handleGetPropertyList(criteria);
    }
  }, [handleGetPropertyList, terms]);

  return (
    <div className={s.wrapper}>
      <div>
        {' '}
        <MapComponent developments={developments} properties={filteredPropertyList!} />
      </div>
    </div>
  );
};

export default Landing;

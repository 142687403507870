import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
// import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, Modal, Progress, Radio, Skeleton, Space } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import TextArea from 'antd/lib/input/TextArea';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import { DndProvider } from 'react-dnd';
// Import DndProvider
import { HTML5Backend } from 'react-dnd-html5-backend';
import mt from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { Form, Icon, Input, Label, Select, Typography, message } from 'ui';
import ScrollToTopButton from 'ui/scrollToTop';
import { formRules } from 'utils/formRules';

// import { formRules } from 'utils/formRules';
import { createActivePropertySelector } from 'selectors/propertySlice.selector';
import {
  useCreatePropertyMutation,
  useDeleteAgentMutation,
  useDeletePropertyResourceMutation,
  useInviteAgentMutation,
  useLazyGetPropertyAgenciesQuery,
  useLazyGetPropertyDetailQuery, // useDeletePropertyImageMutation,
  useLazyGetPropertyInfoQuery,
  useUpdatePropertyMutation,
  useUploadPropertyImageMutation,
  useUploadPropertyResourceMutation,
} from 'services/properties/propertyApiService';
import { setActiveProperty, setInitialView, updateFilteredProperties } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import {
  CommercialTypeEnum,
  FinishStatusEnum,
  GroundRentTypeEnum,
  OrientationTypeEnum,
  PostingTypeEnum,
  SaleStatusEnum, // SaleStatusEnum,
} from 'types/property/propertyEnum';
import { PropertyType } from 'types/property/propertyTypes';

import Card from './DndCard';
import s from './PostProperty.module.scss';
import {
  contactVisibilityTypeOption,
  createPropertyContext,
  groundRentTypeOption,
  orientationType,
  postTypeOption,
  propertyTypeOption, // statusOption,
} from './postProperty.utils';
import PropertyLocationVenue from './property-location-venue';
import { PreparePropertyLocationVenueResult } from './property-location-venue/propertyLocationVenue.utils';

const { confirm } = Modal;

const PostProperty: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const selectedCommercialType = Form.useWatch('commercialType', form);
  const selectedType = Form.useWatch('type', form);
  const groundRentType = Form.useWatch('groundRentType', form);
  const postingType = Form.useWatch('postingType', form);
  const internalSize = Form.useWatch(['parameters', '11'], form);
  const externalSize = Form.useWatch(['parameters', '12'], form);
  const [getPropertyData, { data: propertyInfo }] = useLazyGetPropertyInfoQuery();
  const [uploadPropertyImage] = useUploadPropertyImageMutation();
  const [uploadPropertyResource] = useUploadPropertyResourceMutation();
  const [createProperty, { isLoading: creating }] = useCreatePropertyMutation();
  const [updateProperty, { isLoading: updating }] = useUpdatePropertyMutation();
  const [getPropertyDetail, { isFetching }] = useLazyGetPropertyDetailQuery();
  const [getPropertyAgencies] = useLazyGetPropertyAgenciesQuery();
  const [inviteAgency, { isLoading: inviting }] = useInviteAgentMutation();
  const [deleteAgent] = useDeleteAgentMutation();
  const { propertyId } = useParams();
  const activeProperty = useAppSelector(createActivePropertySelector);
  const [indexFileIdMap, setIndexFileIdMap] = useState<Map<number, string>>(new Map());
  const [images, setImages] = useState<
    {
      id: string;
      imageURL: string;
      description: string;
      isMain: boolean;
    }[]
  >();
  const generateImagesSortOrder = (indexFileIdMap: Map<number, string>) => {
    // Ensure the Map has values
    if (!indexFileIdMap || indexFileIdMap.size === 0) return [];

    // Explicitly type the entries in the Map
    return Array.from(indexFileIdMap.entries()).map(([index, fileId]: [number, string]) => ({
      imageId: fileId,
      sortOrder: index, // The index is used as the sortOrder
    }));
  };
  console.log(indexFileIdMap);
  const moveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setImages((prevCards) => {
        const clonedCards = [...(prevCards || [])];

        const removedItem = clonedCards.splice(dragIndex, 1)[0];
        clonedCards.splice(hoverIndex, 0, removedItem);

        const updatedMap = new Map(clonedCards.map((file, index) => [index, file.id]));

        const imagesSortOrder = generateImagesSortOrder(updatedMap);

        const payload = {
          id: activeProperty?.id,
          coordinates: activeProperty?.coordinates,
          name: activeProperty?.name ?? '',
          totalSize: activeProperty?.totalSize,
          description: activeProperty?.description ?? '',
          saleStatus: activeProperty?.saleStatus ?? SaleStatusEnum.Available, // Replace with appropriate default
          isEditable: activeProperty?.isEditable ?? false,
          postingType: activeProperty?.postingType ?? PostingTypeEnum.Sale, // Replace with appropriate default
          commercialType: activeProperty?.commercialType ?? CommercialTypeEnum.Commercial, // Replace with appropriate default
          finishStatus: activeProperty?.finishStatus ?? FinishStatusEnum.Finished, // Replace with appropriate default
          address: activeProperty?.address ?? {
            street: '',
            town: '',
            postCode: '',
            country: '',
            propertyNumber: '',
          },
          price: activeProperty?.price,
          type: activeProperty?.type ?? '',
          subType: activeProperty?.subType ?? '',
          priceHidden: activeProperty?.priceHidden ?? false,
          imageCount: activeProperty?.imageCount ?? '0',
          agentCount: activeProperty?.agentCount ?? '0',
          imageURL: activeProperty?.images?.find((img) => img.isMain)?.imageURL ?? '', // Extract main image
          watched: activeProperty?.watched!, // Set default or fetch from relevant data
          featuredParameters: activeProperty?.featuredParameters!,
          imagesSortOrder: imagesSortOrder,
        };

        setImages(clonedCards);
        setIndexFileIdMap(updatedMap);

        updateProperty(payload);

        return clonedCards;
      });
    },
    [activeProperty, updateProperty],
  );

  const [deletePropertyResource, { isLoading: docRemoving }] = useDeletePropertyResourceMutation();

  const [loading, setLoading] = useState<string>('');
  const [resourceLoading, setResourceLoading] = useState<string>('');
  const [locationData, setLocationData] = useState<PreparePropertyLocationVenueResult>();
  const [percent, setPercent] = useState<number>(20);
  const [agencyOptions, setAgencyOptions] = useState<{ label: React.ReactNode; value: string }[]>(
    [],
  );
  const [isDirty1, setIsDirty1] = useState<boolean>(false);
  const [isDirty2, setIsDirty2] = useState<boolean>(false);
  const [isDirty3, setIsDirty3] = useState<boolean>(false);
  // const [isDirty4, setIsDirty4] = useState<boolean>(false);
  // const [isDirty5, setIsDirty5] = useState<boolean>(false);
  // validation checking
  const contentRef1 = useRef<HTMLDivElement>(null);
  const contentRef2 = useRef<HTMLDivElement>(null);
  const contentRef3 = useRef<HTMLDivElement>(null);
  const contentRef4 = useRef<HTMLDivElement>(null);
  const contentRef5 = useRef<HTMLDivElement>(null);

  const rentPeriodPriceTypeOptions = [
    { value: 'yearly', label: 'Yearly' },
    { value: 'monthly', label: 'Monthly' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'daily', label: 'Daily' },
  ];

  const scrollToRef = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
  };
  const handleChange = () => {
    if (
      form.isFieldTouched('name') ||
      form.isFieldTouched('commercialType') ||
      form.isFieldTouched('postingType') ||
      form.isFieldTouched('type') ||
      form.isFieldTouched('subType') ||
      form.isFieldTouched('price') ||
      form.isFieldTouched('groundRentType') ||
      form.isFieldTouched('groundRentPrice') ||
      form.isFieldTouched('rentPeriodPriceType')
    ) {
      setIsDirty1(true);
    }

    if (
      form.isFieldTouched(['address', 'country']) ||
      form.isFieldTouched(['address', 'street']) ||
      form.isFieldTouched(['address', 'city']) ||
      form.isFieldTouched(['address', 'postcode']) ||
      form.isFieldTouched(['address', 'propertyNumber']) ||
      form.isFieldTouched('orientationType')
    ) {
      setIsDirty2(true);
    }

    if (
      form.isFieldTouched('finishStatus') ||
      form.isFieldTouched(['parameters', 1]) ||
      form.isFieldTouched(['parameters', 2]) ||
      form.isFieldTouched(['parameters', 3]) ||
      form.isFieldTouched(['parameters', 4]) ||
      form.isFieldTouched(['parameters', 5]) ||
      form.isFieldTouched(['parameters', 6]) ||
      form.isFieldTouched(['parameters', 7]) ||
      form.isFieldTouched(['parameters', 8]) ||
      form.isFieldTouched(['parameters', 9]) ||
      form.isFieldTouched(['parameters', 10]) ||
      form.isFieldTouched(['parameters', 11]) ||
      form.isFieldTouched('specialFeatures') ||
      form.isFieldTouched('comments')
    ) {
      setIsDirty3(true);
    }
  };

  const customRequest = async (options) => {
    const { file, onError } = options;

    try {
      const toBase64 = (file: File) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });

      const fileBase64 = await toBase64(file);

      // Create a payload with the base64 string and other fields

      const payload = {
        id: activeProperty?.id,
        base64Image: fileBase64,
        fileName: file.name,
        description: file.name,
        isMain: true,
      };
      const res = await uploadPropertyImage(payload);

      if ('data' in res) {
        message.success('Image successfully uploaded!');
        setLoading('done');
        setImages(res?.data?.images);
        dispatch(setActiveProperty(res.data as PropertyType));
      }
    } catch (error) {
      setLoading('done');
      onError(error);
    }
  };

  const customDocRequest = async (options) => {
    const { file, onError } = options;

    try {
      const toBase64 = (file: File) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });

      const fileBase64 = await toBase64(file);

      // Create a payload with the base64 string and other fields

      const payload = {
        id: activeProperty?.id,
        base64File: fileBase64,
        fileName: file.name,
        name: file.name,
      };
      const res = await uploadPropertyResource(payload);

      if ('data' in res) {
        message.success('Resource file successfully uploaded!');
        setResourceLoading('done');
        dispatch(setActiveProperty(res.data as PropertyType));
      }
    } catch (error) {
      setResourceLoading('done');
      onError(error);
    }
  };

  const handleInvite = async () => {
    try {
      // const agencyID = form.getFieldValue('agencyID')?.value;
      const agencyEmail = form.getFieldValue('inviteEmail');

      if (!agencyEmail) {
        message.warning('Input agent email.');
        form.setFields([
          {
            name: 'inviteEmail', // Field name
            errors: ['Agent email cannot be empty!'], // Error message
          },
        ]);
        return;
      }

      const agencyData = {
        propertyId: activeProperty?.id!,
        email: agencyEmail,
        // agencyId: agencyID,
      };

      const res = await inviteAgency(agencyData);

      if ('data' in res) {
        message.success('Successfully invited agency');
      } else {
        message.error('Failed to invite agency. Please try again.');
      }
    } catch (error) {
      console.error('Error inviting agency:', error);
      message.error('An unexpected error occurred.');
    }
  };

  const handleSubmit = async (step: string) => {
    try {
      const values = await form.getFieldsValue();
      if (step === 'basicInfo') {
        const isGroundRentRequired =
          groundRentType === GroundRentTypeEnum.GroundRent && postingType === PostingTypeEnum.Sale;

        const fieldValues = form.getFieldsValue(); // Get all form values
        const isGroundRentPriceFieldPresent = 'groundRentPrice' in fieldValues; // Check if field exists

        if (
          form.getFieldValue('name') &&
          form.getFieldValue('price') &&
          (!isGroundRentRequired ||
            (isGroundRentPriceFieldPresent && form.getFieldValue('groundRentPrice')))
        ) {
          const result = activeProperty
            ? {
                id: activeProperty?.id,
                ...values,
                rentPeriodPriceType: values?.rentPeriodPriceType?.value,
                parameters: values.parameters
                  .map((value, index) => ({
                    id: index,
                    value: value || null, // Replace `null` with "string"
                  }))
                  .filter((param) => param.value !== null),
                specialFeatures: values?.specialFeatures
                  ? values?.specialFeatures?.map((value) => ({
                      id: value,
                    }))
                  : null,
                coordinates: {
                  longitude: locationData?.latLng.lng,
                  latitude: locationData?.latLng.lat,
                },
              }
            : {
                ...values,
                rentPeriodPriceType: values?.rentPeriodPriceType?.value,
                parameters: values.parameters
                  .map((value, index) => ({
                    id: index,
                    value: value || null, // Replace `null` with "string"
                  }))
                  .filter((param) => param.value !== null),
                specialFeatures: values?.specialFeatures
                  ? values?.specialFeatures?.map((value) => ({
                      id: value,
                    }))
                  : null,
                coordinates: {
                  longitude: locationData?.latLng.lng,
                  latitude: locationData?.latLng.lat,
                },
              };
          const clonedResult = cloneDeep(result);
          const res = activeProperty?.id
            ? await updateProperty(clonedResult)
            : await createProperty(clonedResult);
          if ('data' in res) {
            activeProperty?.id
              ? message.success('Property successfully updated!')
              : message.success('Property successfully created!');
            scrollToRef(contentRef2);
            setPercent(40);
            dispatch(setActiveProperty(res.data as PropertyType));
            const tempData = {
              id: res.data?.id,
              coordinates: res.data?.coordinates,
              name: res.data?.name,
              totalSize: res.data?.totalSize,
              description: res.data?.description ?? '',
              saleStatus: res.data?.saleStatus ?? SaleStatusEnum.Available, // Replace with appropriate default
              isEditable: res.data?.isEditable ?? false,
              postingType: res.data?.postingType ?? PostingTypeEnum.Sale, // Replace with appropriate default
              commercialType: res.data?.commercialType ?? CommercialTypeEnum.Commercial, // Replace with appropriate default
              finishStatus: res.data?.finishStatus ?? FinishStatusEnum.Finished, // Replace with appropriate default
              address: res.data?.address ?? {
                street: '',
                town: '',
                postCode: '',
                country: '',
                propertyNumber: '',
              },
              price: res.data?.price,
              type: res.data?.type ?? '',
              subType: res.data?.subType ?? '',
              priceHidden: res.data?.priceHidden ?? false,
              imageCount: res.data?.imageCount ?? '0',
              agentCount: res.data?.agentCount ?? '0',
              imageURL: res.data?.images?.find((img) => img.isMain)?.imageURL ?? '', // Extract main image
              watched: res.data?.watched!, // Set default or fetch from relevant data
              featuredParameters: res.data?.featuredParameters!,
            };
            dispatch(updateFilteredProperties(tempData));
          }
        }
      }
      if (step === 'address' && activeProperty?.id) {
        if (values?.address?.street && values?.address?.town) {
          const result = activeProperty
            ? {
                id: activeProperty?.id,
                ...values,
                rentPeriodPriceType: values?.rentPeriodPriceType?.value,
                parameters: values.parameters
                  .map((value, index) => ({
                    id: index,
                    value: value || null, // Replace `null` with "string"
                  }))
                  .filter((param) => param.value !== null),
                specialFeatures: values?.specialFeatures
                  ? values?.specialFeatures?.map((value) => ({
                      id: value,
                    }))
                  : null,
                coordinates: {
                  longitude: locationData?.latLng.lng,
                  latitude: locationData?.latLng.lat,
                },
              }
            : {
                ...values,
                rentPeriodPriceType: values?.rentPeriodPriceType?.value,
                parameters: values.parameters
                  .map((value, index) => ({
                    id: index,
                    value: value || null, // Replace `null` with "string"
                  }))
                  .filter((param) => param.value !== null),
                specialFeatures: values?.specialFeatures
                  ? values?.specialFeatures?.map((value) => ({
                      id: value,
                    }))
                  : null,
                coordinates: {
                  longitude: locationData?.latLng.lng,
                  latitude: locationData?.latLng.lat,
                },
              };
          const clonedResult = cloneDeep(result);
          const res = activeProperty?.id
            ? await updateProperty(clonedResult)
            : await createProperty(clonedResult);
          if ('data' in res) {
            activeProperty?.id
              ? message.success('Property successfully updated!')
              : message.success('Property successfully created!');
            scrollToRef(contentRef3);
            setPercent(60);
            dispatch(setActiveProperty(res.data as PropertyType));
            const tempData = {
              id: res.data?.id,
              coordinates: res.data?.coordinates,
              name: res.data?.name,
              totalSize: res.data?.totalSize,
              description: res.data?.description ?? '',
              saleStatus: res.data?.saleStatus ?? SaleStatusEnum.Available, // Replace with appropriate default
              isEditable: res.data?.isEditable ?? false,
              postingType: res.data?.postingType ?? PostingTypeEnum.Sale, // Replace with appropriate default
              commercialType: res.data?.commercialType ?? CommercialTypeEnum.Commercial, // Replace with appropriate default
              finishStatus: res.data?.finishStatus ?? FinishStatusEnum.Finished, // Replace with appropriate default
              address: res.data?.address ?? {
                street: '',
                town: '',
                postCode: '',
                country: '',
                propertyNumber: '',
              },
              price: res.data?.price,
              type: res.data?.type ?? '',
              subType: res.data?.subType ?? '',
              priceHidden: res.data?.priceHidden ?? false,
              imageCount: res.data?.imageCount ?? '0',
              agentCount: res.data?.agentCount ?? '0',
              imageURL: res.data?.images?.find((img) => img.isMain)?.imageURL ?? '', // Extract main image
              watched: res.data?.watched!, // Set default or fetch from relevant data
              featuredParameters: res.data?.featuredParameters!, // Map or extract if available in `parameters`
            };
            dispatch(updateFilteredProperties(tempData));
          }
        }
      }
      if (step === 'details' && activeProperty?.id) {
        const result = activeProperty
          ? {
              id: activeProperty?.id,
              ...values,
              rentPeriodPriceType: values?.rentPeriodPriceType?.value,
              parameters: values.parameters
                .map((value, index) => ({
                  id: index,
                  value: value || null, // Replace `null` with "string"
                }))
                .filter((param) => param.value !== null),
              specialFeatures: values?.specialFeatures
                ? values?.specialFeatures?.map((value) => ({
                    id: value,
                  }))
                : null,
              coordinates: {
                longitude: locationData?.latLng.lng,
                latitude: locationData?.latLng.lat,
              },
            }
          : {
              ...values,
              rentPeriodPriceType: values?.rentPeriodPriceType?.value,
              parameters: values.parameters
                .map((value, index) => ({
                  id: index,
                  value: value || null, // Replace `null` with "string"
                }))
                .filter((param) => param.value !== null),
              specialFeatures: values?.specialFeatures
                ? values?.specialFeatures?.map((value) => ({
                    id: value,
                  }))
                : null,
              coordinates: {
                longitude: locationData?.latLng.lng,
                latitude: locationData?.latLng.lat,
              },
            };
        const clonedResult = cloneDeep(result);
        const res = activeProperty?.id
          ? await updateProperty(clonedResult)
          : await createProperty(clonedResult);
        if ('data' in res) {
          activeProperty?.id
            ? message.success('Property successfully updated!')
            : message.success('Property successfully created!');
          scrollToRef(contentRef4);
          setPercent(80);
          dispatch(setActiveProperty(res.data as PropertyType));
          const tempData = {
            id: res.data?.id,
            coordinates: res.data?.coordinates,
            name: res.data?.name,
            totalSize: res.data?.totalSize,
            description: res.data?.description ?? '',
            saleStatus: res.data?.saleStatus ?? SaleStatusEnum.Available, // Replace with appropriate default
            isEditable: res.data?.isEditable ?? false,
            postingType: res.data?.postingType ?? PostingTypeEnum.Sale, // Replace with appropriate default
            commercialType: res.data?.commercialType ?? CommercialTypeEnum.Commercial, // Replace with appropriate default
            finishStatus: res.data?.finishStatus ?? FinishStatusEnum.Finished, // Replace with appropriate default
            address: res.data?.address ?? {
              street: '',
              town: '',
              postCode: '',
              country: '',
              propertyNumber: '',
            },
            price: res.data?.price,
            type: res.data?.type ?? '',
            subType: res.data?.subType ?? '',
            priceHidden: res.data?.priceHidden ?? false,
            imageCount: res.data?.imageCount ?? '0',
            agentCount: res.data?.agentCount ?? '0',
            imageURL: res.data?.images?.find((img) => img.isMain)?.imageURL ?? '', // Extract main image
            watched: res.data?.watched!, // Set default or fetch from relevant data
            featuredParameters: res.data?.featuredParameters!,
          };
          dispatch(updateFilteredProperties(tempData));
        }
      }
      if (step === 'last' && activeProperty?.id) {
        const result = activeProperty
          ? {
              id: activeProperty?.id,
              ...values,
              rentPeriodPriceType: values?.rentPeriodPriceType?.value,
              parameters: values.parameters
                .map((value, index) => ({
                  id: index,
                  value: value || null, // Replace `null` with "string"
                }))
                .filter((param) => param.value !== null),
              specialFeatures: values?.specialFeatures
                ? values?.specialFeatures?.map((value) => ({
                    id: value,
                  }))
                : null,
              coordinates: {
                longitude: locationData?.latLng.lng,
                latitude: locationData?.latLng.lat,
              },
            }
          : {
              ...values,
              rentPeriodPriceType: values?.rentPeriodPriceType?.value,
              parameters: null,
              specialFeatures: null,
              coordinates: null,
            };
        const clonedResult = cloneDeep(result);
        const res = activeProperty?.id
          ? await updateProperty(clonedResult)
          : await createProperty(clonedResult);
        if ('data' in res) {
          activeProperty?.id
            ? message.success('Property successfully saved!')
            : message.success('Property successfully created!');
          scrollToRef(contentRef5);
          dispatch(setInitialView(undefined));
          setPercent(100);
          dispatch(setActiveProperty(res.data as PropertyType));
          const tempData = {
            id: res.data?.id,
            coordinates: res.data?.coordinates,
            name: res.data?.name,
            totalSize: res.data?.totalSize,
            description: res.data?.description ?? '',
            saleStatus: res.data?.saleStatus ?? SaleStatusEnum.Available, // Replace with appropriate default
            isEditable: res.data?.isEditable ?? false,
            postingType: res.data?.postingType ?? PostingTypeEnum.Sale, // Replace with appropriate default
            commercialType: res.data?.commercialType ?? CommercialTypeEnum.Commercial, // Replace with appropriate default
            finishStatus: res.data?.finishStatus ?? FinishStatusEnum.Finished, // Replace with appropriate default
            address: res.data?.address ?? {
              street: '',
              town: '',
              postCode: '',
              country: '',
              propertyNumber: '',
            },
            price: res.data?.price,
            type: res.data?.type ?? '',
            subType: res.data?.subType ?? '',
            priceHidden: res.data?.priceHidden ?? false,
            imageCount: res.data?.imageCount ?? '0',
            agentCount: res.data?.agentCount ?? '0',
            imageURL: res.data?.images?.find((img) => img.isMain)?.imageURL ?? '', // Extract main image
            watched: res.data?.watched!, // Set default or fetch from relevant data
            featuredParameters: res.data?.featuredParameters!,
          };
          dispatch(updateFilteredProperties(tempData));
          navigate(DASHBOARD_ROUTE);
        }
      }
    } catch (errorInfo) {
      console.log('Validation Failed:', errorInfo);
    }
  };

  const handleDeleteResource = useCallback(
    async (id: string) => {
      const res = await deletePropertyResource(id);
      if ('data' in res) {
        message.warning('Resource file removed!');
        dispatch(setActiveProperty(res.data as PropertyType));
      }
    },
    [dispatch, deletePropertyResource],
  );

  const handleGetProperty = useCallback(
    async (propertyId: string) => {
      const res = await getPropertyDetail(propertyId);
      if ('data' in res) {
        setImages(res?.data?.images);
        res?.data?.images?.map((file, index) => {
          setIndexFileIdMap((prevMap) => new Map(prevMap).set(index, file.id));
        });
        if (res?.data?.saleStatus) {
          form.setFieldValue('saleStatus', res?.data?.saleStatus);
        }
        if (res?.data?.name) {
          form.setFieldValue('name', res?.data?.name);
        }
        if (res?.data?.rentPeriodPriceType) {
          form.setFieldValue('rentPeriodPriceType', res?.data?.rentPeriodPriceType?.toLowerCase());
        }
        if (res?.data?.commercialType) {
          form.setFieldValue('commercialType', res?.data.commercialType);
        }
        if (res?.data?.postingType) {
          form.setFieldValue('postingType', res?.data.postingType);
        }
        if (res?.data?.type) {
          form.setFieldValue('type', res?.data.type);
        }
        if (res?.data?.price) {
          form.setFieldValue('price', res?.data.price);
        }
        if (res?.data?.groundRentType) {
          form.setFieldValue('groundRentType', res?.data.groundRentType);
        }
        if (res?.data?.groundRentPrice) {
          form.setFieldValue('groundRentPrice', res?.data.groundRentPrice);
        }
        if (res?.data?.orientationType) {
          form.setFieldValue('orientationType', res?.data.orientationType);
        }
        if (res?.data?.finishStatus) {
          form.setFieldValue('finishStatus', res?.data.finishStatus);
        }
        if (res?.data?.parameters?.length! > 0) {
          res?.data?.parameters!.map((item) => {
            form.setFieldValue(['parameters', item.id], item.value);
          });
        }
        if (res?.data?.specialFeatures) {
          const specialFeatures = res?.data.specialFeatures.map((item) => item.id);
          form.setFieldValue('specialFeatures', specialFeatures);
        }
        if (res?.data?.comments) {
          form.setFieldValue('comments', res?.data.comments);
        }
        if (res?.data?.owner) {
          form.setFieldValue('phone', res?.data?.owner?.mobileNumber);
        }

        if (res?.data?.address) {
          form.setFieldValue(['address', 'street'], res?.data?.address?.street);
          form.setFieldValue(['address', 'postcode'], res?.data?.address?.postCode);
          form.setFieldValue(['address', 'town'], res?.data?.address?.town);
          form.setFieldValue(['address', 'propertyNumber'], res?.data?.address?.propertyNumber);
          const tempLocation = {
            latLng: {
              lat: res?.data?.coordinates?.latitude,
              lng: res?.data?.coordinates?.longitude,
            },
          };
          setLocationData(tempLocation);
        }
        dispatch(setActiveProperty(res.data as PropertyType));
      }
    },
    [dispatch, form, getPropertyDetail],
  );

  useEffect(() => {
    if (propertyId) {
      handleGetProperty(propertyId);
    }
  }, [propertyId, getPropertyAgencies, handleGetProperty]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getPropertyAgencies(); // Optional: use unwrap to access data or handle errors
        if ('data' in res) {
          const formattedOptions = res?.data?.map((agency: any) => ({
            label: (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <img
                  src={agency.profilePicture}
                  alt={agency.name}
                  style={{ width: 24, height: 24, borderRadius: '50%' }}
                />
                {agency?.name}
              </div>
            ),
            value: agency.id,
          }));
          setAgencyOptions(formattedOptions!);
        }
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, [getPropertyAgencies]);

  const showAgentDeleteConfirm = async (email: string) => {
    confirm({
      title: 'Would you like to remove this Agent?',
      icon: <InfoCircleOutlined />,
      content: '',
      centered: true,
      mask: true,
      async onOk() {
        if (activeProperty?.id) {
          const request = {
            propertyId: activeProperty?.id,
            email: email,
          };
          const res = await deleteAgent(request);
          if ('data' in res) {
            dispatch(setActiveProperty(res.data as PropertyType));
            message.warning('Property Agent removed!');
          }
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    if (!activeProperty) {
      form.resetFields();
    }
  }, [activeProperty, form]);

  // useEffect(() => {

  // }, [activeProperty, form]);

  useEffect(() => {
    if (internalSize && externalSize) {
      form.setFieldValue('totalSize', parseInt(internalSize, 10) + parseInt(externalSize, 10));
    }
  }, [internalSize, externalSize, form]);

  useEffect(() => {
    if (selectedCommercialType === 'residential') {
      if (!activeProperty) {
        form.setFieldValue('type', 'Apartment');
      } else {
        if (activeProperty?.commercialType !== 'residential') {
          form.setFieldValue('type', 'Apartment');
        } else {
          form.setFieldValue('type', activeProperty?.type);
        }
      }
      getPropertyData(selectedCommercialType);
    } else if (selectedCommercialType === 'commercial') {
      if (!activeProperty) {
        form.setFieldValue('type', 'Office');
      } else {
        if (activeProperty?.commercialType !== 'commercial') {
          form.setFieldValue('type', 'Office');
        } else {
          form.setFieldValue('type', activeProperty?.type);
        }
      }
      getPropertyData(selectedCommercialType);
    }
  }, [selectedCommercialType, getPropertyData, form, activeProperty]);

  useEffect(() => {
    if (selectedType === 'Apartment') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Apartment');
      } else {
        if (activeProperty?.type === 'Apartment') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Apartment');
        }
      }
    } else if (selectedType === 'House') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Terraced house');
      } else {
        if (activeProperty?.type === 'House') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Terraced house');
        }
      }
    } else if (selectedType === 'Garage') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Lockup Garage');
      } else {
        if (activeProperty?.type === 'Garage') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Lockup Garage');
        }
      }
    } else if (selectedType === 'Plot') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Site for development');
      } else {
        if (activeProperty?.type === 'Plot') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Site for development');
        }
      }
    } else if (selectedType === 'Office') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Office');
      } else {
        if (activeProperty?.type === 'Office') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Office');
        }
      }
    } else if (selectedType === 'Retail') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Shop');
      } else {
        if (activeProperty?.type === 'Retail') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Shop');
        }
      }
    } else if (selectedType === 'Catering') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Restaurant');
      } else {
        if (activeProperty?.type === 'Catering') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Restaurant');
        }
      }
    } else if (selectedType === 'Hospitality') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Hotel');
      } else {
        if (activeProperty?.type === 'Hospitality') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Hotel');
        }
      }
    } else if (selectedType === 'WareHouse') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'WareHouse');
      } else {
        if (activeProperty?.type === 'WareHouse') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'WareHouse');
        }
      }
    } else if (selectedType === 'Industrial') {
      if (!activeProperty) {
        form.setFieldValue('subType', 'Factory');
      } else {
        if (activeProperty?.type === 'Industrial') {
          form.setFieldValue('subType', activeProperty?.subType);
        } else {
          form.setFieldValue('subType', 'Factory');
        }
      }
    }
  }, [selectedType, form, activeProperty]);

  const subTypeContent = {
    Apartment: (
      <Space direction="vertical">
        <Radio value="Apartment">Apartment</Radio>
        <Radio value="Maisonette">Maisonette</Radio>
        <Radio value="Ground Floor Maisonette">Ground Floor Maisonette</Radio>
        <Radio value="Penthouse">Penthouse</Radio>
        <Radio value="Studio">Studio</Radio>
      </Space>
    ),
    House: (
      <Space direction="vertical">
        <Radio value="Terraced house">Terraced house</Radio>
        <Radio value="Townhouse">Townhouse</Radio>
        <Radio value="Semi detached villa">Semi detached villa</Radio>
        <Radio value="Detached villa">Detached villa</Radio>
        <Radio value="Farmhouse">Farmhouse</Radio>
        <Radio value="House of Character">House of Character</Radio>
        <Radio value="Bungalow">Bungalow</Radio>
        <Radio value="Palazzo, Manor">Palazzo, Manor</Radio>
      </Space>
    ),
    Garage: (
      <Space direction="vertical">
        <Radio value="Lockup Garage">Lockup Garage</Radio>
        <Radio value="Car Space">Car Space</Radio>
      </Space>
    ),
    Plot: (
      <Space direction="vertical">
        <Radio value="Site for development">Site for development</Radio>
        <Radio value="Plot for development">Plot for development</Radio>
        <Radio value="ODZ Land">ODZ Land</Radio>
      </Space>
    ),
  };

  const commercialTypeContent = {
    Office: (
      <Space direction="vertical">
        <Radio value="Office">Office</Radio>
      </Space>
    ),
    Retail: (
      <Space direction="vertical">
        <Radio value="Shop">Shop</Radio>
        <Radio value="Showroom">Showroom</Radio>
        <Radio value="Clinic">Clinic</Radio>
      </Space>
    ),
    Catering: (
      <Space direction="vertical">
        <Radio value="Restaurant">Restaurant</Radio>
        <Radio value="Take-Away">Take-Away</Radio>
        <Radio value="Bar">Bar</Radio>
        <Radio value="Club">Club</Radio>
      </Space>
    ),
    Hospitality: (
      <Space direction="vertical">
        <Radio value="Hotel">Hotel</Radio>
        <Radio value="GuestHouse">GuestHouse</Radio>
      </Space>
    ),
    WareHouse: (
      <Space direction="vertical">
        <Radio value="WareHouse">WareHouse</Radio>
      </Space>
    ),
    Industrial: (
      <Space direction="vertical">
        <Radio value="Factory">Factory</Radio>
        <Radio value="Workshop">Workshop</Radio>
      </Space>
    ),
  };

  return (
    <div className={s.wrapper}>
      <ScrollToTopButton />
      <div className={s.leftMenu}>
        <div className={s.progressLabel}>
          <Typography type="h5">PROGRESS</Typography>
          <Typography type="h5">
            {percent / 20} <span style={{ color: '#7F86A4' }}>OF 5</span>
          </Typography>
        </div>
        <Progress percent={percent} type="line" />

        <ul style={{ listStyle: 'none', marginTop: 32 }}>
          <li className={cn(s.menuItem, { [s.active]: percent === 20 })}>
            <Button
              type="link"
              onClick={() => {
                setPercent(20);
                scrollToRef(contentRef1);
              }}>
              1. Type & Price
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: percent === 40 })}>
            <Button
              type="link"
              onClick={() => {
                scrollToRef(contentRef2);
                setPercent(40);
              }}>
              2. Location & Agent
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: percent === 60 })}>
            <Button
              type="link"
              onClick={() => {
                setPercent(60);
                scrollToRef(contentRef3);
              }}>
              3. Details & Features
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: percent === 80 })}>
            <Button
              type="link"
              onClick={() => {
                setPercent(80);
                scrollToRef(contentRef4);
              }}>
              4. Photos & Files
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: percent === 100 })}>
            <Button
              type="link"
              onClick={() => {
                setPercent(100);
                scrollToRef(contentRef5);
              }}>
              5. Contact details
            </Button>
          </li>
        </ul>
      </div>
      <createPropertyContext.Provider value={{ form, onChangeForm: handleChange }}>
        <Form
          className={s.form}
          form={form}
          onValuesChange={handleChange}
          onFinish={handleSubmit}
          initialValues={{
            type: 'Apartment',
            subType: 'Apartment',
          }}>
          <Typography type="h2">Your property</Typography>
          {isFetching ? (
            <div className={s.formBlock}>
              <Skeleton loading={isFetching} active avatar paragraph={{ rows: 5 }}></Skeleton>
            </div>
          ) : (
            <div className={s.formBlock}>
              <div className={s.anchor} ref={contentRef1}></div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <span className={s.subTitle}>1</span>
                <Typography type="h4">Type & Price</Typography>
              </div>
              <div className={s.propertyItem}>
                <Typography type="h5">Property title</Typography>
                <div>
                  <Form.Item name="name" rules={[formRules.required]}>
                    <Input
                      name="name"
                      autoComplete="off"
                      style={{ backgroundColor: 'white', width: '90%' }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={s.propertyItem}>
                <Typography type="h5">Commercial type</Typography>
                <Form.Item name="commercialType" initialValue={CommercialTypeEnum.Residential}>
                  <Radio.Group
                    className={s.filterSwitch}
                    options={propertyTypeOption}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
              <div className={s.propertyItem}>
                <Typography type="h5">Type of posting</Typography>
                <Form.Item name="postingType" initialValue={PostingTypeEnum.Sale}>
                  <Radio.Group
                    className={s.filterSwitch}
                    options={postTypeOption}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
              {selectedCommercialType === CommercialTypeEnum.Residential ? (
                <div className={s.propertyItemStart}>
                  <Typography type="h5">Property</Typography>
                  <Form.Item name="type">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="Apartment">Apartment, Maisonette or Penthouse</Radio>
                        <Radio value="House">House</Radio>
                        <Radio value="Garage">Garage</Radio>
                        <Radio value="Plot">Plot, Land or Field</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
              ) : (
                <div className={s.propertyItemStart}>
                  <Typography type="h5">Commercial Property</Typography>
                  <Form.Item name="type">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="Office">Office</Radio>
                        <Radio value="Retail">Retail</Radio>
                        <Radio value="Catering">Catering</Radio>
                        <Radio value="Hospitality">Hospitality</Radio>
                        <Radio value="WareHouse">Warehouse</Radio>
                        <Radio value="Industrial">Industrial</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </div>
              )}
              <Divider style={{ borderColor: '#CED2E5' }} />
              <div className={s.propertyItemStart}>
                <Typography type="h5">Type of property</Typography>
                <Form.Item name="subType">
                  {selectedCommercialType === CommercialTypeEnum.Residential ? (
                    <Radio.Group>{subTypeContent[selectedType!]} </Radio.Group>
                  ) : (
                    <Radio.Group>{commercialTypeContent[selectedType!]} </Radio.Group>
                  )}
                </Form.Item>
              </div>
              {postingType === PostingTypeEnum.Rent && (
                <div className={s.propertyItem}>
                  <Typography type="h5">Rent Period</Typography>
                  <div>
                    <Form.Item
                      name="rentPeriodPriceType"
                      initialValue={rentPeriodPriceTypeOptions[0].value}
                      // rules={[formRules.required]}
                    >
                      <Select
                        style={{ width: '50%' }}
                        options={rentPeriodPriceTypeOptions}></Select>
                    </Form.Item>
                  </div>
                </div>
              )}
              <div className={s.propertyItem}>
                <Typography type="h5">Price €</Typography>
                <div>
                  <Form.Item name="price" rules={[formRules.required]}>
                    <Input.Number
                      name="price"
                      size="large"
                      formatter={(value) =>
                        value ? `€ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ''
                      }
                      parser={(value) => (value ? value.replace(/€\s?|,/g, '') : '')}
                      style={{ backgroundColor: 'white', width: '50%', borderRadius: 6 }}
                    />
                  </Form.Item>
                </div>
              </div>

              {postingType !== PostingTypeEnum.Rent && (
                <div className={s.propertyItem}>
                  <Typography type="h5">Ground Rent</Typography>
                  <Form.Item name="groundRentType" initialValue={GroundRentTypeEnum.FreeHold}>
                    <Radio.Group
                      className={s.filterSwitch}
                      options={groundRentTypeOption}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                </div>
              )}
              {groundRentType === GroundRentTypeEnum.GroundRent &&
                postingType === PostingTypeEnum.Sale && (
                  <div className={s.propertyItem}>
                    <Typography type="h5">Ground Rent Price (per year) €</Typography>
                    <div>
                      <Form.Item name="groundRentPrice" rules={[formRules.required]}>
                        <Input.Number
                          name="groundRentPrice"
                          size="large"
                          formatter={(value) =>
                            value
                              ? `€ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                              : ''
                          }
                          parser={(value) => (value ? value.replace(/€\s?|,/g, '') : '')}
                          style={{ backgroundColor: 'white', width: '50%', borderRadius: 6 }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
              <div className={s.Submit}>
                <Button
                  type="primary"
                  loading={creating || updating}
                  disabled={!isDirty1}
                  htmlType="submit"
                  onClick={() => handleSubmit('basicInfo')}
                  style={{ width: 300, height: 50 }}>
                  {activeProperty?.id ? 'SAVE' : 'SAVE PROPERTY'}
                </Button>
              </div>
            </div>
          )}
          {isFetching ? (
            <div className={s.formBlock}>
              <Skeleton loading={isFetching} active avatar paragraph={{ rows: 5 }}></Skeleton>
            </div>
          ) : (
            <div className={s.formBlock}>
              <div className={s.anchor} ref={contentRef2}></div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <span className={s.subTitle}>2</span>
                <Typography type="h4">Location & Agent Assignment</Typography>
              </div>
              {activeProperty?.agents?.length! > 0 && <Divider />}
              {activeProperty?.agents?.length! > 0 && (
                <div className={s.propertyItemStart}>
                  <Typography type="h5">Agent’s managing this property</Typography>
                  <div>
                    {activeProperty?.agents &&
                      activeProperty?.agents?.map((agent, index) => (
                        <div key={index} className={s.agencyItem}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                            {agent?.agencyId && (
                              <img
                                style={{ borderRadius: '50%' }}
                                src={agent?.agencyProfileImageURL}
                                width={32}
                                height={32}
                                alt="agency"></img>
                            )}
                            <Typography type="h5" color="primary" weight={700}>
                              {agent?.agencyName}
                            </Typography>
                            <Typography type="main" color="grey-medium">
                              {agent?.firstName} {agent?.lastName}
                            </Typography>
                          </div>
                          <Button
                            htmlType="button"
                            onClick={() => showAgentDeleteConfirm(agent?.email)}
                            style={{
                              borderRadius: 6,
                              fontSize: 12,
                              backgroundColor: '#EBDDFF',
                              color: '#8E53E8',
                              borderColor: '#EBDDFF',
                            }}>
                            REMOVE
                          </Button>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <Divider />
              <div className={s.inviteWrapper}>
                <Typography type="h5">Invite agent to manage this property</Typography>
                <div className={s.inviteInput}>
                  {agencyOptions?.length > 0 && (
                    <Form.Item name="agencyID">
                      <Select
                        fullWidth
                        style={{ display: 'none' }}
                        placeholder="Select agency"
                        options={agencyOptions}></Select>
                    </Form.Item>
                  )}
                  <Form.Item name="inviteEmail" style={{ width: 300 }}>
                    <Input
                      name="inviteEmail"
                      autoComplete="off"
                      onChange={() => form.setFields([{ name: 'inviteEmail', errors: [] }])}
                      fullWidth
                      placeholder="E-mail"
                    />
                  </Form.Item>
                  {form && (
                    <Button
                      disabled={!activeProperty?.id}
                      type="primary"
                      style={{ borderRadius: 4 }}
                      htmlType="button"
                      loading={inviting}
                      onClick={handleInvite}>
                      INVITE
                    </Button>
                  )}
                </div>
              </div>
              {(locationData || !propertyId) && (
                <PropertyLocationVenue
                  locationData={locationData}
                  setLocationData={setLocationData}
                  setIsDirty2={setIsDirty2}
                />
              )}
              <div className={s.propertyItem}>
                <Typography type="h5">Orientation</Typography>
                <Form.Item name="orientationType" initialValue={OrientationTypeEnum.North}>
                  <Radio.Group
                    className={s.filterSwitch}
                    options={orientationType}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
              <div className={s.Submit}>
                <Button
                  type="primary"
                  loading={creating || updating}
                  disabled={!activeProperty?.id || !isDirty2 || !locationData}
                  htmlType="submit"
                  onClick={() => handleSubmit('address')}
                  style={{ width: 300, height: 50, marginBottom: 16 }}>
                  SAVE
                </Button>
                {locationData === undefined && (
                  <Typography color="red" type="main">
                    Please specify location
                  </Typography>
                )}
              </div>
            </div>
          )}
          {isFetching ? (
            <div className={s.formBlock}>
              <Skeleton loading={isFetching} active avatar paragraph={{ rows: 5 }}></Skeleton>
            </div>
          ) : (
            <div className={s.formBlock}>
              <div className={s.anchor} ref={contentRef3}></div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <span className={s.subTitle}>3</span>
                <Typography type="h4">Details & Features</Typography>
              </div>
              {selectedCommercialType === CommercialTypeEnum.Residential &&
                selectedType?.toLowerCase() !== 'plot' && (
                  <div className={s.propertyItemStart}>
                    <Typography type="h5">Finish Type</Typography>
                    <Form.Item name="finishStatus" initialValue="finished">
                      <Radio.Group>
                        <Space direction="vertical">
                          <Radio value="finished">Finished</Radio>
                          <Radio value="partlyFinished">Partly Finished</Radio>
                          <Radio value="furnished">Furnished</Radio>
                          <Radio value="partlyFurnished">Partly Furnished</Radio>
                          <Radio value="shell">Shell</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                )}
              {propertyInfo?.map(
                (item, index) =>
                  selectedType?.toLowerCase() === item.name.split(',')[0].trim().toLowerCase() && (
                    <div key={index} className={s.parameterTypes}>
                      {item.parameterTypes.map(
                        (parameterType, subIndex) =>
                          parameterType.name !== 'phone' &&
                          !(postingType === PostingTypeEnum.Rent && parameterType.id === 6) && (
                            <div key={subIndex}>
                              <Label>{parameterType.caption}</Label>
                              <Form.Item name={['parameters', parameterType.id]}>
                                <Input
                                  autoComplete="off"
                                  style={{ backgroundColor: 'white', width: '100%' }}
                                  name={parameterType.id.toString()}
                                />
                              </Form.Item>
                            </div>
                          ),
                      )}
                      <div style={{ width: '100%' }}>
                        <Label>Total size m²</Label>
                        <Form.Item name="totalSize">
                          <Input
                            autoComplete="off"
                            style={{ backgroundColor: 'white', width: '100%' }}
                            name="totalSize"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  ),
              )}

              <div className={s.propertyItemStart}>
                <Typography type="h5">Special Features</Typography>
                <Form.Item name="specialFeatures">
                  <Checkbox.Group style={{ width: '100%', marginLeft: 8 }}>
                    {propertyInfo?.map(
                      (item, index) =>
                        item.name?.toLowerCase() === selectedType?.toLowerCase() && (
                          <Space key={index} direction="vertical">
                            {item.specialFeatures.map((specialFeature, subIndex) => (
                              <Checkbox key={subIndex} value={specialFeature.id}>
                                {specialFeature.name}
                              </Checkbox>
                            ))}
                          </Space>
                        ),
                    )}
                  </Checkbox.Group>
                </Form.Item>
              </div>

              <div className={s.propertyCommentItem}>
                <Typography type="h5">About Property</Typography>
                <Form.Item name="comments">
                  <TextArea className={s.comments} name="comments" rows={7} />
                </Form.Item>
              </div>
              <div className={s.Submit}>
                <Button
                  type="primary"
                  loading={creating || updating}
                  disabled={!activeProperty?.id || !isDirty3}
                  htmlType="button"
                  onClick={() => handleSubmit('details')}
                  style={{ width: 300, height: 50 }}>
                  SAVE
                </Button>
              </div>
            </div>
          )}
          {isFetching ? (
            <div className={s.formBlock}>
              <Skeleton loading={isFetching} active avatar paragraph={{ rows: 5 }}></Skeleton>
            </div>
          ) : (
            <div className={s.formBlock}>
              <div className={s.anchor} ref={contentRef4}></div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <span className={s.subTitle}>4</span>
                <Typography type="h4">Photos & Files</Typography>
              </div>

              <div style={{ marginTop: 24 }}>
                <div className={s.uploadedFiles}>
                  {activeProperty?.images?.length! > 0 && (
                    <div style={{ marginTop: 8, marginBottom: 8 }}>
                      <Typography type="h5">PHOTOS</Typography>
                    </div>
                  )}
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8, overflowX: 'auto' }}>
                    <DndProvider backend={HTML5Backend}>
                      {images?.map((file, index) => (
                        <Card
                          key={index}
                          src={file.imageURL}
                          id={file.id}
                          index={index}
                          description={file?.description}
                          moveImage={moveImage}
                        />
                      ))}
                    </DndProvider>
                  </div>
                  {loading && (
                    <div className={s.fileUpload}>
                      <div
                        className={`${s.fileContent} ${loading === 'uploading' ? s.fill : ''} ${
                          loading === 'done' ? s.done : ''
                        }`}
                      />
                    </div>
                  )}
                </div>
                <div className={s.uploadWrapper}>
                  <Typography type="h3" weight={700}>
                    Photos
                  </Typography>
                  <div className={s.upload}>
                    <Dragger
                      disabled={!activeProperty?.id || loading === 'uploading'}
                      hasControlInside={true}
                      customRequest={customRequest}
                      accept=".jpg, .jpeg, .png, .gif, .mp4 .webp"
                      maxCount={1}
                      showUploadList={false}
                      beforeUpload={(file) => {
                        const isImageOrVideo = file.type.startsWith('image/');
                        // setFileType(file.type.startsWith('image/') ? 'image' : 'video');
                        if (!isImageOrVideo) {
                          message.error('Only image files can be uploaded!');
                        }
                        return isImageOrVideo;
                      }}
                      onChange={(info) => {
                        if (info.file.status === 'uploading') {
                          setLoading('uploading');
                          return;
                        }
                        if (info.file.status === 'done') {
                          message.success(`${info.file.name} successfully uploaded!`);
                          setLoading('done');
                        } else if (info.file.status === 'error') {
                          message.error(`${info.file.name} upload failed.`);
                        }
                      }}>
                      <div className={s.uploadBlock}>
                        <Button
                          disabled={!activeProperty?.id}
                          htmlType="button"
                          loading={loading === 'uploading'}
                          className={s.chooseButton}>
                          CHOOSE FILES
                        </Button>
                        <Typography type="main" color="grey">
                          or drag PNG, JPEG or WEBP less than 5MB
                        </Typography>
                      </div>
                    </Dragger>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 36 }}>
                <div className={s.uploadedFiles}>
                  {activeProperty?.resources?.length! > 0 && (
                    <div style={{ marginTop: 8, marginBottom: 8 }}>
                      <Typography type="h5">FILES</Typography>
                    </div>
                  )}
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 16, overflowX: 'auto' }}>
                    {activeProperty?.resources?.map((file) => (
                      <div key={file.id} className={s.uploadedDocFile}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                          <Icon name="file" />
                          <span>{file.name}</span>
                        </div>
                        <Button
                          disabled={docRemoving}
                          className={s.deleteDocButton}
                          type="link"
                          onClick={() => handleDeleteResource(file.id)}>
                          <Icon name="trashBox" size={14} />
                        </Button>
                      </div>
                    ))}
                  </div>
                  {resourceLoading && (
                    <div className={s.fileUpload}>
                      <div
                        className={`${s.fileContent} ${
                          resourceLoading === 'uploading' ? s.fill : ''
                        } ${resourceLoading === 'done' ? s.done : ''}`}
                      />
                    </div>
                  )}
                </div>
                <div className={s.uploadWrapper}>
                  <Typography type="h3" weight={700}>
                    Files
                  </Typography>
                  <div className={s.upload}>
                    <Dragger
                      disabled={!activeProperty?.id || resourceLoading === 'uploading'}
                      hasControlInside={true}
                      customRequest={customDocRequest}
                      accept=".doc, .pdf, 'docx"
                      maxCount={1}
                      showUploadList={false}
                      beforeUpload={(file) => {
                        const isDoc =
                          file.type.endsWith('/doc') ||
                          file.type.endsWith('/pdf') ||
                          file.type.endsWith('/docx');
                        // setFileType(file.type.startsWith('image/') ? 'image' : 'video');
                        if (!isDoc) {
                          message.error('Only doc files can be uploaded!');
                        }
                        return isDoc;
                      }}
                      onChange={(info) => {
                        if (info.file.status === 'uploading') {
                          setResourceLoading('uploading');
                          return;
                        }
                        if (info.file.status === 'done') {
                          message.success(`${info.file.name} successfully uploaded!`);
                          setResourceLoading('done');
                        } else if (info.file.status === 'error') {
                          message.error(`${info.file.name} upload failed.`);
                        }
                      }}>
                      <div className={s.uploadBlock}>
                        <Button
                          disabled={!activeProperty?.id}
                          htmlType="button"
                          loading={resourceLoading === 'uploading'}
                          className={s.chooseButton}>
                          CHOOSE FILES
                        </Button>
                        <Typography type="main" color="grey">
                          or drag DOC, DOCX or PDF less than 5MB
                        </Typography>
                      </div>
                    </Dragger>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isFetching ? (
            <div className={s.formBlock}>
              <Skeleton loading={isFetching} active avatar paragraph={{ rows: 5 }}></Skeleton>
            </div>
          ) : (
            <div className={s.formBlock}>
              <div className={s.anchor} ref={contentRef5}></div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <span className={s.subTitle}>5</span>
                <Typography type="h4">Contact details</Typography>
              </div>
              <div className={s.propertyItem}>
                <Typography type="h5">Owner’s contacts visibility</Typography>
                <Form.Item name="ownerVisibility" initialValue="public">
                  <Radio.Group
                    className={s.filterSwitch}
                    options={contactVisibilityTypeOption}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </div>
              <div className={s.propertyItem}>
                <Typography type="h5">Phone</Typography>
                <Form.Item name="phone">
                  <PhoneInput
                    containerClass={s.phoneContainer}
                    inputClass={s.phone}
                    buttonClass={s.phoneButton}
                    regions="europe"
                    preferredCountries={['mt']}
                    country="mt"
                    localization={mt}
                  />
                </Form.Item>
              </div>
              <div className={s.propertyItem}>
                <Typography type="h5">E-mail</Typography>
                <Form.Item name="email">
                  <Input autoComplete="off" name="email" fullWidth />
                </Form.Item>
              </div>
            </div>
          )}
          <div className={s.Submit}>
            <Button
              disabled={!activeProperty?.id || !locationData}
              style={{ width: 300, height: 50 }}
              loading={creating || updating}
              type="primary"
              htmlType="button"
              onClick={() => handleSubmit('last')}>
              {activeProperty ? 'SAVE' : 'SAVE'}
            </Button>
          </div>
        </Form>
      </createPropertyContext.Provider>
    </div>
  );
};
export default PostProperty;

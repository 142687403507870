import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Development, DevelopmentDetailType } from 'types/promoters/promoterTypes';
import {
  GetPropertyType,
  InitialViewType,
  PropertyType,
  TermsType,
} from 'types/property/propertyTypes';

export type PropertySliceType = {
  activeProperty?: PropertyType;
  propertyList?: GetPropertyType[];
  filteredPropertyList?: GetPropertyType[];
  terms?: TermsType;
  activeDevelopment?: DevelopmentDetailType;
  developmentList?: Development[];
  initialView?: InitialViewType;
};

export const initialState: PropertySliceType = {};

export const propertySlice = createSlice({
  name: 'propertySlice',
  initialState,
  reducers: {
    setActiveProperty: (state, action: PayloadAction<PropertyType | undefined>) => {
      state.activeProperty = action.payload;
    },
    setActiveDevelopment: (state, action: PayloadAction<DevelopmentDetailType | undefined>) => {
      state.activeDevelopment = action.payload;
    },
    setPropertyList: (state, action: PayloadAction<GetPropertyType[] | undefined>) => {
      state.propertyList = action.payload;
    },
    setDevelopmentList: (state, action: PayloadAction<Development[] | undefined>) => {
      state.developmentList = action.payload;
    },
    setFilteredPropertyList: (state, action: PayloadAction<GetPropertyType[] | undefined>) => {
      state.filteredPropertyList = action.payload;
    },
    setInitialView: (state, action: PayloadAction<InitialViewType | undefined>) => {
      state.initialView = action.payload;
    },
    setTerms: (state, action: PayloadAction<Partial<TermsType | undefined>>) => {
      state.terms = {
        ...state.terms,
        ...action.payload,
      };
    },
    updateFilteredProperties: (state, action: PayloadAction<GetPropertyType>) => {
      const index = state.propertyList?.findIndex((p) => p.id === action.payload.id);
      if (index !== undefined && index !== -1 && state.propertyList) {
        // Update existing property
        state.propertyList[index] = action.payload;
      } else {
        // Add new property
        state.propertyList?.push(action.payload);
      }
    },
    updateDevelopments: (state, action: PayloadAction<Development>) => {
      const index = state.developmentList?.findIndex((p) => p.id === action.payload.id);
      if (index !== undefined && index !== -1 && state.developmentList) {
        state.developmentList[index] = action.payload;
      } else {
        state.developmentList?.push(action.payload);
      }
    },
    removePropertyById: (state, action) => {
      const idToRemove = action.payload; // Get the id passed in the action
      state.propertyList = state.propertyList?.filter((property) => property.id !== idToRemove);
    },
    removeDevelopmentById: (state, action) => {
      const idToRemove = action.payload; // Get the id passed in the action
      state.developmentList = state.developmentList?.filter((dev) => dev.id !== idToRemove);
    },
  },
});

export const {
  setActiveProperty,
  setDevelopmentList,
  setActiveDevelopment,
  setTerms,
  setInitialView,
  setPropertyList,
  removePropertyById,
  removeDevelopmentById,
  updateFilteredProperties,
  updateDevelopments,
  setFilteredPropertyList,
} = propertySlice.actions;

export const MY_PROPERTIES_ROUTE = '/listings';

export const MY_DEVELOPMENT_ROUTE = '/development';

export const CREATE_DEVELOPMENT_FORM_ROUTE = `${MY_DEVELOPMENT_ROUTE}/create-development`;

export const EDIT_DEVELOPMENT_FORM_ROUTE = `${MY_DEVELOPMENT_ROUTE}/edit-development/:developmentId`;

export const DEVELOPMENT_DETAIL_ROUTE = `${MY_DEVELOPMENT_ROUTE}/detail/:developmentId`;

export const CREATE_PROPERTY_FORM_ROUTE = `${MY_PROPERTIES_ROUTE}/create-listing`;

export const EDIT_PROPERTY_FORM_ROUTE = `${MY_PROPERTIES_ROUTE}/edit-listing/:propertyId`;

export const PROPERTY_DETAIL_ROUTE = `${MY_PROPERTIES_ROUTE}/detail/:propertyId`;

export const getEditPropertyRoute = (propertyId: string) =>
  EDIT_PROPERTY_FORM_ROUTE.replace(':propertyId', propertyId);

export const getPropertyDetailRoute = (propertyId: string) =>
  PROPERTY_DETAIL_ROUTE.replace(':propertyId', propertyId);

export const getEditDevelopmentRoute = (developmentId: string) =>
  EDIT_DEVELOPMENT_FORM_ROUTE.replace(':developmentId', developmentId);

export const getDevelopmentDetailRoute = (developmentId: string) =>
  DEVELOPMENT_DETAIL_ROUTE.replace(':developmentId', developmentId);

import { EventVenueFullInfoType } from 'types/core/locationType';

export interface PreparePropertyLocationVenueResult {
  venueName?: string;
  fullAddress?: string;
  latLng: { lat: number; lng: number };
  country?: string;
  town?: string;
  street?: string;
  postcode?: string;
}
export const preparePropertyLocationVenue = (
  venue: EventVenueFullInfoType,
  fullAddress: string,
): PreparePropertyLocationVenueResult => {
  const result: PreparePropertyLocationVenueResult = {
    venueName: fullAddress,
    fullAddress: fullAddress,
    latLng: {
      lat: venue.geometry.location.lat(),
      lng: venue.geometry.location.lng(),
    },
    street: venue.vicinity,
  };

  // venue.address_components.forEach((item) => {
  //   if (item.types.includes('country')) {
  //     result.country = item.long_name;
  //   }
  //   if (item.types.includes('route')) {
  //     result.street = item.long_name;
  //   }
  //   if (item.types.includes('postal_code')) {
  //     result.postcode = item.long_name;
  //   }
  //   if (item.types.includes('locality')) {
  //     result.town = item.long_name;
  //   }
  // });

  return result;
};

export const EUROPE_COUNTRIES = [
  'mt',
  'at',
  'be',
  'bg',
  'hr',
  'cy',
  'cz',
  'dk',
  'ee',
  'fi',
  'fr',
  'de',
  'gr',
  'hu',
  'is',
  'ie',
  'it',
  'lv',
  'li',
  'lt',
  'lu',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'sk',
  'si',
  'es',
  'se',
  'ch',
  'gb',
];

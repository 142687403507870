import React, { FC } from 'react';

import { Button } from 'antd';
import { handleCutString } from 'tools/cutstring';
import { Icon, Label, Typography } from 'ui';

import { GetPropertyType } from 'types/property/propertyTypes';

// Import Mapbox CSS
import s from './PropertyTableCard.module.scss';

interface PropertyCardProps {
  property: GetPropertyType;
  isActive: boolean;
  onClick: () => void;
}

const formatCurrency = (value) => {
  return `€${value.toLocaleString()}`;
};

const formatPrice = (value: number): string => {
  if (value >= 1_000_000) {
    return `€${(value / 1_000_000).toFixed(1)} M`;
  } else if (value >= 1_000) {
    return `€${(value / 1_000).toFixed(1)} K`;
  } else {
    return `€${value}`;
  }
};

const PropertyTableCard: FC<PropertyCardProps> = ({ property, isActive, onClick }) => {
  return (
    <div className={s.wrapper} onClick={onClick} onKeyDown={() => {}} role="button" tabIndex={0}>
      <div className={`${s.propertyLink} ${isActive ? s.active : ''}`}>
        <div style={{ position: 'relative', textAlign: 'center' }}>
          {property?.imageURL ? (
            <div style={{ cursor: 'pointer' }} role="button" tabIndex={0} onKeyDown={() => {}}>
              <img src={property?.imageURL} alt="property" className={s.propertyImage} />
            </div>
          ) : (
            <div style={{ cursor: 'pointer' }} role="button" tabIndex={0} onKeyDown={() => {}}>
              <img src="/assets/images/no-image.svg" alt="property" className={s.propertyImage} />
            </div>
          )}
          <Button className={s.imageCount} type="link">
            <Icon name="camera" size={14} />
            {property?.imageCount}
          </Button>
        </div>
        <div className={s.info}>
          <Typography className={s.price} weight={700}>
            {formatCurrency(property?.price)}
          </Typography>
          <Typography className={s.address}>
            {' '}
            {handleCutString(property?.name || '', 25, true)}
          </Typography>
          <Label>
            {property.featuredParameters
              .map((param) => `${param.value} ${param.shortenedCaption}`)
              .join(' • ')}{' '}
            • {property.totalSize}м²
          </Label>
        </div>
        <div className={s.mobileInfo}>
          <Typography type="h6" weight={700}>
            {formatPrice(property?.price)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default PropertyTableCard;

import { FC, useEffect } from 'react';

import { Empty } from 'antd';
import { Tabs, TabsProps, Typography } from 'ui';

import { useLazyGetInviteListsQuery } from 'services/user-management/userManagementApiService';

import { InviteStatusEnum } from 'types/property/propertyEnum';

import s from './Notification.module.scss';
import InvitationCard from './invitation';

const Notifications: FC = () => {
  const [getInviteList, { data }] = useLazyGetInviteListsQuery();

  useEffect(() => {
    const criteria = {
      pageNumber: 1,
      pageSize: 100,
      status: InviteStatusEnum.Pending,
    };
    getInviteList(criteria);
  }, [getInviteList]);

  const items: TabsProps['items'] = [
    // {
    //   key: '1',
    //   label: 'All',
    //   children: 'Content of Tab Pane 1',
    // },
    {
      key: '2',
      label: 'Invites',
      children: (
        <div>
          {data?.invites?.length ? (
            data.invites.map((invite, index) => (
              <InvitationCard key={index} invite={invite} isActive={true} />
            ))
          ) : (
            <>
              <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{ height: 150 }}
                description={<Typography type="h3">No Invites</Typography>}
                className={s.empty}></Empty>
            </>
          )}
        </div>
      ),
    },
    // {
    //   key: '3',
    //   label: 'Properties',
    //   children: 'Content of Tab Pane 3',
    // },
  ];

  return (
    <div className={s.wrapper}>
      <Typography type="h4">Notifications</Typography>

      <Tabs style={{ marginTop: 24 }} items={items} />
    </div>
  );
};
export default Notifications;

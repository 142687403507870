import { FC, useCallback, useEffect, useState } from 'react';

import {
  GlobalOutlined,
  MailOutlined,
  PhoneFilled, // StarFilled,
} from '@ant-design/icons';
import { Skeleton } from 'antd';
// import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { Icon, Typography } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { useLazyGetDevelopmentDetailQuery } from 'services/promoter/promoterApiService';

import ImageGrid from 'pages/propertyDetail/imageGrid';

import s from './Developer.module.scss';

// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const Developer: FC = () => {
  const { developmentId } = useParams();
  // const currentUser = useAppSelector(ProfileSelector);
  const [getDevelopmentDetail, { data, isLoading }] = useLazyGetDevelopmentDetailQuery();

  const handleGetDevelopment = useCallback(
    (developmentId: string) => {
      getDevelopmentDetail(developmentId);
    },
    [getDevelopmentDetail],
  );

  const [images, setImages] = useState<
    {
      original: string;
      thumbnail: string;
      originalHeight?: number;
      originalWidth?: number;
    }[]
  >();

  useEffect(() => {
    if (data) {
      const tempImages = data?.images!.map((item) => ({
        original: item?.imageURL ? item?.imageURL : '/assets/images/no-image.svg',
        thumbnail: item?.imageURL ? item?.imageURL : '/assets/images/no-image.svg',
      }));

      setImages(tempImages);
    }
  }, [data]);

  useEffect(() => {
    if (developmentId) {
      handleGetDevelopment(developmentId);
    }
  }, [developmentId, handleGetDevelopment]);

  return (
    <div className={s.wrapper}>
      <div className={s.agencyWrapper}>
        <div className={s.topPart}>
          {/* <img
            src="/assets/images/avatar/avatar.svg"
            width={50}
            height={50}
            style={{ borderRadius: '50%' }}
            alt="Agency"
          /> */}

          <Typography type="h4">{data?.name}</Typography>
          <Typography type="label"> Development</Typography>
          {/* <div className={s.starWrapper}>
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
            <StarFilled style={{ color: '#E4D43C', fontSize: 24 }} />
          </div> */}
        </div>
        <div className={s.middlePart}>
          {isLoading ? (
            <Skeleton style={{ width: 200 }} loading={isLoading} active paragraph={{ rows: 4 }} />
          ) : (
            <>
              <div className={s.agencyItem}>
                <PhoneFilled style={{ color: '#7F86A4', fontSize: 20 }} />
                <Typography type="h5" weight={700}>
                  {data?.phone}
                </Typography>
              </div>
              {data?.websiteURL && (
                <div className={s.agencyItem}>
                  <GlobalOutlined style={{ color: '#7F86A4', fontSize: 20 }} />
                  <a
                    href={
                      data?.websiteURL?.startsWith('http')
                        ? data.websiteURL
                        : `https://${data?.websiteURL}`
                    }
                    className={s.siteLink}
                    target="_blank">
                    {' '}
                    {data?.websiteURL}
                  </a>
                </div>
              )}
              <div className={s.agencyItem}>
                <MailOutlined style={{ color: '#7F86A4', fontSize: 20 }} />
                <Typography type="h5" weight={700}>
                  {data?.email}
                </Typography>
              </div>
              {/* <div className={s.agencyItem}>
                <FacebookFilled style={{ color: '#7F86A4', fontSize: 20 }} />
                <Typography type="h5" weight={700}>
                  {data?.facebookLink}
                </Typography>
              </div> */}
            </>
          )}
        </div>
        <div className={s.bottomPart}>
          <Typography type="h5">ABOUT</Typography>
          <Typography type="label">{data?.description}</Typography>
        </div>
        {data?.resources &&
          data?.resources.map((item, index) => (
            <div key={index} className={s.item}>
              <div className={s.iconWrapper}>
                <Icon name="doc" size={32} />
              </div>
              {/* <Document file={item?.url}>
                <Page pageNumber={1} />
              </Document> */}
              <a href={item.url} download={item.name || 'PDF file'} className={s.link}>
                <Typography type="h6">{item.name || 'PDF file'}</Typography>
              </a>
            </div>
          ))}
      </div>
      <div className={s.propertyContents}>
        <div className={s.imageGallery}>{images && <ImageGrid images={images} />}</div>
        <Typography type="h3">Available Properties</Typography>
        <div className={s.cardsWrapper}>
          {data?.properties?.map((item, index) => (
            <PropertyCard key={index} property={item} editable={false} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Developer;

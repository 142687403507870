import { useEffect } from 'react';

import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const GA_MEASUREMENT_ID = 'G-N1BM95J51D';

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA only once when the component mounts
    ReactGA.initialize(GA_MEASUREMENT_ID);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return null;
};

export default Analytics;

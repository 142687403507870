import { FC, useCallback, useEffect } from 'react';

import { DeleteOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button as AntButton, Modal, Spin } from 'antd';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { CREATE_DEVELOPMENT_FORM_ROUTE, CREATE_PROPERTY_FORM_ROUTE } from 'routes/property/list';
import { Icon, Label, Typography, message } from 'ui';
import Button from 'ui/button/button';
import PropertyCard from 'ui/propertyCard';

import { ProfileSelector } from 'selectors/userSlice.selectors';
import { useDeleteAllPropertiesMutation } from 'services/properties/propertyApiService';
import { useUserPropertiesMutation } from 'services/user-management/userManagementApiService';
import { setActiveDevelopment, setActiveProperty } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { USER_TYPE_ENUM } from 'types/core/userTypes';
import { PropertyStatusEnum } from 'types/property/propertyEnum';

import s from './Properties.module.scss';

const { confirm } = Modal;

const Properties: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [getUserProperties, { data: userProperties, isLoading }] = useUserPropertiesMutation();
  const [deleteAllProperties] = useDeleteAllPropertiesMutation();

  const currentUser = useAppSelector(ProfileSelector);

  const goToDevelopment = useCallback(() => {
    dispatch(setActiveDevelopment(undefined));
    navigate(CREATE_DEVELOPMENT_FORM_ROUTE);
  }, [navigate, dispatch]);

  const goToProperty = useCallback(() => {
    dispatch(setActiveProperty(undefined));
    navigate(CREATE_PROPERTY_FORM_ROUTE);
  }, [navigate, dispatch]);

  const showExportConfirm = async () => {
    confirm({
      title: 'Would you like to remove all properties?',
      icon: <InfoCircleOutlined />,
      content: '',
      centered: true,
      mask: true,
      async onOk() {
        deleteAllProperties();
        message.warning('All properties removed!');
        navigate(0);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleAllDelete = () => {
    showExportConfirm();
  };

  useEffect(() => {
    const fetchData = () => {
      try {
        const criteria = { pageSize: 100, pageNumber: 1 };
        getUserProperties(criteria); // Optional: use unwrap to access data or handle errors
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, [getUserProperties]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <Typography>Your Property Listings</Typography>
        {currentUser?.userType === USER_TYPE_ENUM.DEVELOPER && (
          <Button className={s.post} onClick={goToDevelopment}>
            LIST DEVELOPMENT
          </Button>
        )}
      </div>
      <div className={s.tools}>
        <Label>
          {userProperties?.properties?.length} properties (
          {
            userProperties?.properties?.filter((item) => item?.status === PropertyStatusEnum.Hidden)
              ?.length
          }{' '}
          hidden )
        </Label>
        <AntButton
          onClick={handleAllDelete}
          type="link"
          icon={<DeleteOutlined />}
          style={{ fontWeight: 600 }}>
          DELETE ALL
        </AntButton>
      </div>

      <div className={classNames(s.components)}>
        {isLoading ? (
          <div
            style={{
              width: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 40,
            }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </div>
        ) : (
          userProperties?.properties?.map((item, index) => (
            <PropertyCard key={index} property={item} editable={true} />
          ))
        )}
        <div
          className={s.addItem}
          onClick={goToProperty}
          onKeyDown={() => {}}
          role="button" // Helps accessibility
          tabIndex={0}>
          <div className={s.centerButton}>
            <Icon name="plus"></Icon>
            Add new listing
          </div>
        </div>
      </div>
    </div>
  );
};
export default Properties;

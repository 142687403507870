import { FC } from 'react';

import { Empty } from 'antd';
import classNames from 'classnames';
import { Typography } from 'ui';
import PropertyCard from 'ui/propertyCard';

import { propertyListSelector } from 'selectors/propertySlice.selector';
import { useAppSelector } from 'store/index';

import s from './Favourites.module.scss';

const Favourites: FC = () => {
  const propertyList = useAppSelector(propertyListSelector);

  const watchedProperties = propertyList?.filter((item) => item.watched) || [];

  return (
    <div className={s.wrapper}>
      <Typography>Favourites</Typography>

      {propertyList && (
        <div className={classNames(s.components)}>
          {watchedProperties?.length > 0 ? (
            watchedProperties.map((item, index) => (
              <PropertyCard key={index} property={item} editable={false} />
            ))
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 150 }}
              description={<Typography type="h3">No favourites</Typography>}
              className={s.empty}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default Favourites;

import { Dispatch, FC, SetStateAction, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Radio } from 'antd';
import { Form, TextArea, Typography } from 'ui';
import Button from 'ui/button/button';

import { useReportPropertyMutation } from 'services/properties/propertyApiService';

import s from './ReportModal.module.scss';

interface ReportModalProps {
  isModalOpen?: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  propertyId?: string;
}

const ReportModal: FC<ReportModalProps> = ({ isModalOpen, setIsModalOpen, propertyId }) => {
  const [reportProperty, { isLoading }] = useReportPropertyMutation();
  const handleOk = () => {
    //
    form
      .validateFields()
      .then(async () => {
        const comments = selectedOption === 'Other' && otherText ? otherText : selectedOption;
        const payload = { id: propertyId!, comments: comments! };
        const res = await reportProperty(payload);

        if ('data' in res) {
          setIsReportSent(true);
        }
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo);
      });
  };
  const [form] = Form.useForm();
  const [isReportSent, setIsReportSent] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [otherText, setOtherText] = useState<string>('');
  const options = [
    'This is a fraud posting',
    'This is my property',
    'Fake photos',
    'Fake contact details',
    'Other',
  ];
  const handleChange = (e: any) => {
    setSelectedOption(e.target.value);
  };
  const handleOtherTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherText(e.target.value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal width={600} centered open={isModalOpen} footer={null} onCancel={handleCancel}>
      {!isReportSent ? (
        <div className={s.wrapper}>
          <div style={{ marginBottom: 24 }}>
            <div style={{ fontSize: 32, fontWeight: 700 }}>Report property</div>
            <Typography type="h6">We will check your report shortly and get back to you</Typography>
          </div>
          <Form form={form} layout="vertical">
            <Form.Item
              name="reportReason"
              rules={[{ required: true, message: 'Please select a reason' }]}>
              <Radio.Group className={s.radioGroup} onChange={handleChange} value={selectedOption}>
                {options.map((option) => (
                  <Radio key={option} value={option}>
                    {option}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            {/* "Other" Text Area */}
            {selectedOption === 'Other' && (
              <Form.Item
                name="otherDetails"
                rules={[
                  { required: true, message: 'Please provide more details' },
                  { min: 5, message: 'Details must be at least 5 characters' },
                ]}>
                <TextArea
                  rows={4}
                  className={s.other}
                  placeholder="Please provide more details..."
                  value={otherText}
                  onChange={handleOtherTextChange}
                  name="otherDetails"
                />
              </Form.Item>
            )}

            {/* Buttons */}
            <div className={s.buttonGroup}>
              <Button
                style={{
                  width: 160,
                  color: '#8E53E8',
                  backgroundColor: '#EBDDFF',
                }}
                onClick={() => {
                  form.resetFields();
                  handleCancel();
                }}>
                CANCEL
              </Button>
              <Button loading={isLoading} type="primary" style={{ width: 160 }} onClick={handleOk}>
                SEND REPORT
              </Button>
            </div>
          </Form>
        </div>
      ) : (
        <div
          style={{
            textAlign: 'center',
            fontSize: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: 24,
            paddingBottom: 24,
          }}>
          <div style={{ textAlign: 'left', fontSize: '16px' }}>
            <ExclamationCircleOutlined style={{ fontSize: 32 }} />
            <div style={{ fontSize: 32, fontWeight: 700 }}>Report sent</div>
            <Typography type="h6">Thank you for submitting a report</Typography>
            <div className={s.buttonGroup}>
              <Button
                style={{ width: 320 }}
                onClick={() => {
                  setIsReportSent(false);
                  setIsModalOpen(false);
                }}>
                ok
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
export default ReportModal;

import { SERVICES_TAGS } from '../../../constants/servicesTags';
import {
  AgencyInvitesResponse,
  LoginAsUserResponseType,
  UserEventsResponseType,
  userListResType,
} from 'services/user-management/userManagement.api.type';

import {
  ProfileType,
  UserProfileImageType,
  UserProfileType,
  UserType,
  UserUpdateReqType,
} from 'types/core/userTypes';
import { AcceptInvitationEnum, InviteStatusEnum } from 'types/property/propertyEnum';
import { InviteResType, getPropertiesResType } from 'types/property/propertyTypes';
import {
  LoginRequestProps,
  RefreshTokenRequestProps,
  RegisterRequestProps,
  RegisterResponseProps,
} from 'types/user-management/auth';

import initialApiService from '../initialApiService';

const userManagementApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.query<UserType, LoginRequestProps>({
      query: (body) => ({
        url: '/users/login',
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation<RegisterResponseProps, RegisterRequestProps>({
      query: (body) => ({
        url: '/users/register',
        method: 'POST',
        body,
      }),
    }),
    refreshToken: builder.mutation<UserType, RefreshTokenRequestProps>({
      query: (body) => ({
        url: '/users/refresh-token',
        method: 'POST',
        body,
      }),
    }),
    getCurrentUser: builder.query<ProfileType, void>({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.UPDATE_PROMOTER],
    }),
    uploadProfilePicture: builder.mutation<ProfileType, UserProfileImageType>({
      query: (body) => ({
        url: '/users/profilepicture',
        method: 'PATCH',
        body,
      }),
    }),
    editProfile: builder.mutation<ProfileType, UserUpdateReqType>({
      query: (body) => ({
        url: '/users/edit',
        method: 'PATCH',
        body,
      }),
    }),
    changePassword: builder.mutation<
      ProfileType,
      { oldPassword: string; newPassword: string; confirmPassword: string }
    >({
      query: (body) => ({
        url: '/users/changepassword',
        method: 'PATCH',
        body,
      }),
    }),
    searchUser: builder.query<UserProfileType[], { term: string }>({
      query: (params) => ({
        url: '/users/search',
        method: 'GET',
        params,
      }),
    }),
    getUserEvents: builder.query<
      UserEventsResponseType,
      { IncludeMetrics?: boolean; PageSize?: number; PageNumber?: number; Search?: string }
    >({
      query: (params) => ({
        url: '/users/events',
        method: 'GET',
        params,
      }),
      providesTags: [SERVICES_TAGS.GET_USERS_EVENT],
    }),
    getInviteLists: builder.query<
      AgencyInvitesResponse,
      { status: InviteStatusEnum; PageSize?: number; PageNumber?: number }
    >({
      query: (params) => ({
        url: '/users/invites',
        method: 'GET',
        params,
      }),
    }),
    processInvite: builder.mutation<InviteResType, { status: AcceptInvitationEnum; id: string }>({
      query: (body) => ({
        url: '/users/acceptrejectinvite',
        method: 'POST',
        body,
      }),
    }),
    userProperties: builder.mutation<
      getPropertiesResType,
      { pageNumber: number; pageSize: number }
    >({
      query: (body) => ({
        url: '/users/properties',
        method: 'POST',
        body,
      }),
    }),
    getUserList: builder.query<UserType[], string>({
      query: (params) => ({
        url: `/admin/searchusers?term=${params}`,
        method: 'GET',
      }),
    }),
    loginAsUser: builder.mutation<LoginAsUserResponseType, { email: string }>({
      query: (body) => ({
        url: '/admin/loginas',
        method: 'POST',
        body,
      }),
    }),
    listUser: builder.mutation<userListResType, { pageNumber: number; pageSize: number }>({
      query: (body) => ({
        url: '/admin/users',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const loginEndpoint = userManagementApiService.endpoints.login;

export const {
  useLazyLoginQuery,
  useListUserMutation,
  useUserPropertiesMutation,
  useRegisterMutation,
  useLazyGetInviteListsQuery,
  useProcessInviteMutation,
  useLazyGetCurrentUserQuery,
  useGetCurrentUserQuery,
  useUploadProfilePictureMutation,
  useEditProfileMutation,
  useChangePasswordMutation,
  useLazySearchUserQuery,
  useLazyGetUserEventsQuery,
  useLazyGetUserListQuery,
  useLoginAsUserMutation,
} = userManagementApiService;

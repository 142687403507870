import React, { FC, useCallback, useEffect } from 'react';

import { Button } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ABOUT_ROUTE, DASHBOARD_ROUTE, FAQ_ROUTE, PRICING_ROUTE } from 'routes/dashboard/list';
import { CREATE_PROPERTY_FORM_ROUTE } from 'routes/property/list';
import { LANDING_ROUTE, LOGIN_ROUTE, PROFILE_ROUTE } from 'routes/user-management/list';
import 'tools/hooks/debounce.hook';
import { Icon, Typography, message } from 'ui';

import { propertyListSelector } from 'selectors/propertySlice.selector';
import { useGetDevelopmentListMutation } from 'services/promoter/promoterApiService';
import { useGetPropertiesMutation } from 'services/properties/propertyApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActiveProperty, setDevelopmentList, setPropertyList } from 'slices/propertySlice';
import { setProfile } from 'slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { Development } from 'types/promoters/promoterTypes';
import { GetPropertyType } from 'types/property/propertyTypes';

import s from './NavBar.module.scss';
import Filters from './filters';

interface NavBarProps {
  initiallyLogged: boolean;
}

const NavBar: FC<NavBarProps> = ({ initiallyLogged }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getProperties] = useGetPropertiesMutation();
  const [getDevelopmentList] = useGetDevelopmentListMutation();
  const properties = useAppSelector(propertyListSelector);

  const location = useLocation();

  const [getCurrentuser, { data: profile }] = useLazyGetCurrentUserQuery();
  const handleGoToProfile = useCallback(() => {
    navigate(`${PROFILE_ROUTE}?step=userProfile`);
  }, [navigate]);

  const goToDashboard = useCallback(() => {
    navigate(LANDING_ROUTE);
  }, [navigate]);

  const handleGoToAbout = useCallback(() => {
    navigate(ABOUT_ROUTE);
  }, [navigate]);

  const handleGoToFaq = useCallback(() => {
    navigate(FAQ_ROUTE);
  }, [navigate]);

  const handleGoToPricing = useCallback(() => {
    navigate(PRICING_ROUTE);
  }, [navigate]);

  const handleGoToLogin = useCallback(() => {
    navigate(LOGIN_ROUTE);
  }, [navigate]);

  const goToProperty = useCallback(() => {
    if (initiallyLogged) {
      dispatch(setActiveProperty(undefined));
      navigate(CREATE_PROPERTY_FORM_ROUTE);
    } else {
      message.warning('You need to log in before listing a property.');
      navigate(DASHBOARD_ROUTE);
    }
  }, [navigate, initiallyLogged, dispatch]);

  const goToFavourites = useCallback(() => {
    if (initiallyLogged) {
      navigate('profile?step=favourites');
    } else {
      message.warning('You need to log in before create a development.');
      navigate(DASHBOARD_ROUTE);
    }
  }, [navigate, initiallyLogged]);

  useEffect(() => {
    if (initiallyLogged) {
      getCurrentuser();
    }
  }, [getCurrentuser, initiallyLogged]);

  useEffect(() => {
    if (profile) {
      dispatch(setProfile(profile));
    }
  }, [profile, dispatch]);

  const handleGetPropertyList = useCallback(
    async (criteria) => {
      const res = await getProperties(criteria);
      if ('data' in res) {
        dispatch(setPropertyList(res.data.properties as GetPropertyType[]));
      }
    },
    [dispatch, getProperties],
  );

  useEffect(() => {
    const criteria = {
      pageNumber: 1,
      pageSize: 200,
    };

    handleGetPropertyList(criteria);
  }, [handleGetPropertyList]);

  const handleGetDevList = useCallback(
    async (criteria) => {
      const res = await getDevelopmentList(criteria);
      if ('data' in res) {
        dispatch(setDevelopmentList(res.data.developments as Development[]));
      }
    },
    [dispatch, getDevelopmentList],
  );

  useEffect(() => {
    const criteria = {
      pageNumber: 1,
      pageSize: 200,
    };

    handleGetDevList(criteria);
  }, [handleGetDevList]);

  return (
    <div className={`${s.entireWrapper} ${location.pathname === '/' ? s.dashboardNavbar : ''}`}>
      <div className={s.navWrapper}>
        <Button style={{ paddingLeft: 0 }} type="link" onClick={goToDashboard}>
          <Icon width={200} name="logo" />
        </Button>
        <div className={s.menu}>
          <Button
            type="link"
            onClick={goToDashboard}
            className={`${s.menuItem} ${location.pathname === '/' ? s.active : ''}`}>
            Map
          </Button>
          {initiallyLogged && (
            <Button
              onClick={goToFavourites}
              style={{ color: '#8E53E8', display: 'flex', alignItems: 'center', gap: 4 }}
              type="link">
              <Typography type="h6" weight={700}>
                {properties?.filter((item) => item.watched).length}
              </Typography>
              <Icon name="heartFilled" size={20} color="#8E53E8" />
            </Button>
          )}
          <Button
            type="link"
            onClick={handleGoToAbout}
            className={`${s.menuItem} ${location.pathname === '/about' ? s.active : ''}`}>
            About
          </Button>
          <Button
            type="link"
            onClick={handleGoToFaq}
            className={`${s.menuItem} ${location.pathname === '/faq' ? s.active : ''}`}>
            FAQ
          </Button>
          <Button
            type="link"
            onClick={handleGoToPricing}
            className={`${s.menuItem} ${location.pathname === '/pricing' ? s.active : ''}`}>
            Pricing
          </Button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          <Button className={s.post} onClick={goToProperty}>
            LIST PROPERTY
          </Button>
          {initiallyLogged ? (
            <Button onClick={handleGoToProfile} className={s.actions}>
              <div className={s.profile}>
                {profile?.profileImageURL &&
                  profile?.profileImageURL !==
                    'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/' && (
                    <div className={s.uploadedFile}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <img
                          className={s.uploadedImage}
                          src={profile?.profileImageURL}
                          width={24}
                          height={24}
                          alt="promotionMedia"
                        />
                      </div>
                    </div>
                  )}
                {!profile?.profileImageURL ||
                  (profile?.profileImageURL ===
                    'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/' && (
                    <div className={s.uploadedFile}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <img
                          className={s.uploadedImage}
                          src="/assets/images/avatar/avatar.svg"
                          width={24}
                          height={24}
                          alt="promotionMedia"
                        />
                      </div>
                    </div>
                  ))}
              </div>
              <span className={s.text}>MY PROFILE</span>
            </Button>
          ) : (
            <Button onClick={handleGoToLogin} className={s.actions}>
              LOG IN
            </Button>
          )}
        </div>
      </div>
      <Filters />
    </div>
  );
};
export default NavBar;

import { ReactElement, forwardRef, useEffect, useState } from 'react';

import cn from 'classnames';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { MY_EVENTS_ROUTE } from '../../routes/events/list';
import Icon, { TIconsLiteral } from '../icon';
import Typography, { TypographyTypes } from '../typography';
import s from './NavLink.module.scss';

export interface NavLinkProps {
  label: string | ReactElement;
  to: string;
  icon?: TIconsLiteral;
  typographyType?: TypographyTypes;
  eventCounter?: number;
  typographyClassName?: string;
  className?: string;
  weight?: 500 | 600 | 700 | undefined;
}

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const [active, setActive] = useState(false);
  const {
    label,
    to = '',
    icon,
    className = '',
    typographyType = 'h4',
    typographyClassName = '',
    eventCounter = 0,
    weight = 500,
  } = props;

  useEffect(() => {
    setActive(window.location.pathname === to);
  }, [to]);

  return (
    <RouterNavLink
      ref={ref}
      className={({ isActive }) => {
        return cn(s.link, { [className]: className, [s.active]: isActive });
      }}
      to={to}>
      <>
        {icon && <Icon name={icon} className={s.icon} size={20} />}
        <Typography type={typographyType} className={typographyClassName} weight={weight}>
          {label}{' '}
          {to === MY_EVENTS_ROUTE && eventCounter > 0 && (
            <span className={cn(s.counter, { [s.active]: active })}>{eventCounter}</span>
          )}
        </Typography>
      </>
    </RouterNavLink>
  );
});

export default NavLink;

import React, { useCallback } from 'react';

import { Button, Typography } from 'antd';
import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom';
import { LANDING_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE } from 'routes/user-management/list';
import { Icon } from 'ui';

import LandingImage from '../landing-image/LandingImage';
import s from './Dashboard.module.scss';

const { Title } = Typography;

const Dashboard = () => {
  const navigate = useNavigate();
  const goToDashboard = useCallback(() => {
    navigate(LANDING_ROUTE);
  }, [navigate]);
  return (
    <div className={s.landingWrapper}>
      <LandingImage />
      <div className={s.content}>
        <Button
          style={{ paddingLeft: 0, justifyContent: 'flex-start' }}
          type="link"
          onClick={goToDashboard}>
          <Icon name="logo" className={s.logo} />
        </Button>
        <Title className={s.landingTitle}>Find your place</Title>
        <p className={s.subTitle}>
          Location and price of properties on our interactive map of Malta.
        </p>
        <ul style={{ marginLeft: 24 }}>
          <li className={s.list}>List for free</li>
          <li className={s.list}>For property owners, seekers and agents.</li>
          <li className={s.list}>First users will get discounted premium accounts.</li>
        </ul>
        <div className={s.buttonGroup}>
          <RouterNavLink style={{ width: '100%' }} to={REGISTER_ROUTE}>
            <Button className={s.firstButton}>SIGN UP</Button>
          </RouterNavLink>
          <RouterNavLink style={{ width: '100%' }} to={LOGIN_ROUTE}>
            <Button className={s.secondButton}>LOG IN</Button>
          </RouterNavLink>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

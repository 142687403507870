import { FC, useState } from 'react';

import { Form, Modal, Radio } from 'antd';
import { Button, Input, Typography } from 'ui';

import { getPropertyAgenciesResType } from 'types/property/propertyTypes';

import s from './ContactCard.module.scss';

interface ContactCardProps {
  isAgency?: boolean;
  price?: number;
  agenciesList?: getPropertyAgenciesResType;
  firstName?: string;
  lastName?: string;
  profileImageURL?: string;
  period?: string;
}

const formatCurrency = (value) => {
  return `€${value.toLocaleString()}`;
};

const ContactCard: FC<ContactCardProps> = ({
  isAgency,
  price,
  firstName,
  lastName,
  profileImageURL,
  agenciesList,
  period,
}) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values: any) => {
    console.log('Form Values:', values);
  };

  const RadioGroupContent = isAgency ? (
    agenciesList?.map((item, index) => (
      <div key={index} className={s.customRadio} style={{ cursor: 'pointer', marginBottom: 8 }}>
        <Radio value={`option-${index}`} style={{ width: '100%' }}>
          <div className={s.radioContent}>
            <img
              src={
                item?.profilePicture
                  ? `${item?.profilePicture}`
                  : '/assets/images/avatar/avatar.svg'
              }
              alt={`Option ${index + 1}`}
              style={{ width: 50, height: 50, borderRadius: '50%' }}
            />
            <div>
              <Typography type="h6" weight={700}>
                {item?.name || 'Alex Candy'} {/* Replace with actual data */}
              </Typography>
              <Typography color="grey" type="main">
                Agent
              </Typography>
            </div>
          </div>
        </Radio>
      </div>
    ))
  ) : (
    <div className={s.customRadio} style={{ cursor: 'pointer', marginBottom: 8 }}>
      <Radio value="option1" style={{ width: '100%' }}>
        <div className={s.radioContent}>
          <img
            src={
              profileImageURL
                ? `https://onthatstreet.blob.core.windows.net/content/users/profilepictures/${profileImageURL}`
                : '/assets/images/avatar/avatar.svg'
            }
            alt="Option 1"
            style={{ width: 50, height: 50 }}
          />
          <div>
            <Typography type="h6" weight={700}>
              {firstName} {lastName}
            </Typography>
            <Typography color="grey" type="main">
              Owner
            </Typography>
          </div>
        </div>
      </Radio>
    </div>
  );

  return (
    <div className={s.wrapper}>
      <div className={s.imageWrapper}>
        <div>
          <Typography type="h3" weight={800}>
            {formatCurrency(price)}
          </Typography>
          {period && <Typography type="label">Per {period}</Typography>}
        </div>
      </div>
      <div className={s.infoWrapper} style={{ display: 'none' }}>
        <div className={s.agenciesImage}>
          <Button fullWidth onClick={showModal}>
            CONTACT {isAgency ? 'AGENCY' : 'OWNER'}
          </Button>
        </div>
      </div>
      <Modal
        width={600}
        centered
        title={
          <div style={{ fontSize: 24, fontWeight: 700 }}>
            Contact {isAgency ? 'agent' : 'owner'}
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        <Form form={form} onFinish={onFinish} layout="vertical" className={s.form}>
          <Form.Item
            name="selectedOption"
            initialValue="option1"
            rules={[{ required: true, message: 'Please select an option!' }]}>
            <Radio.Group
              onChange={(e) => console.log('Selected:', e.target.value)}
              style={{ width: '100%' }}>
              {RadioGroupContent}
            </Radio.Group>
          </Form.Item>
          <div style={{ marginTop: 16 }}></div>
          <Typography type="h4">Share your info</Typography>
          <Form.Item name="name" rules={[{ required: true, message: 'Please input name' }]}>
            <div>
              <Input
                style={{ backgroundColor: 'white', marginTop: 8 }}
                name="name"
                placeholder="Your name"
                size="middle"
              />
            </div>
          </Form.Item>
          <Form.Item name="phone" rules={[{ required: true, message: 'Please input name!' }]}>
            <div>
              <Input
                style={{ backgroundColor: 'white', marginTop: 24 }}
                name="phone"
                placeholder="Your phone"
                size="middle"
              />
            </div>
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true, message: 'Please input name!' }]}>
            <div>
              <Input
                style={{ backgroundColor: 'white', marginTop: 24 }}
                name="email"
                placeholder="E-mail"
                size="middle"
              />
            </div>
          </Form.Item>
          <div style={{ marginTop: 16 }}></div>
          <Typography type="h5">Message</Typography>
          <Form.Item name="message" rules={[{ required: true, message: 'Please input name!' }]}>
            <div>
              <Input
                style={{ backgroundColor: 'white', marginTop: 8 }}
                name="message"
                placeholder="E-mail"
                size="middle"
              />
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ marginTop: 24, marginBottom: 20 }}
              type="primary"
              block
              htmlType="submit">
              CONTACT {isAgency ? 'AGENT' : 'OWNER'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
export default ContactCard;

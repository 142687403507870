import { FC, useCallback } from 'react';

import { Button } from 'antd';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/user-management/list';
import { removeTokenCookies } from 'tools/cookieTools';
import { useQuery, useSetQuery } from 'tools/hooks';

import { ProfileSelector } from 'selectors/userSlice.selectors';
import { setTerms } from 'slices/propertySlice';
import { logout } from 'slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { USER_TYPE_ENUM } from 'types/core/userTypes';

import s from './Profile.module.scss';
import Admin from './admin';
import Develpments from './developments';
import Favourites from './favourites';
import Messages from './messages';
import Notifications from './notifications';
import Properties from './properties';
import Subscription from './subscription';
import UserProfile from './user-profile';

const Profile: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setQuery = useSetQuery();
  const query = useQuery();
  const step = query.get('step');
  const currentUser = useAppSelector(ProfileSelector);

  const handleChangeStep = useCallback(
    (step: string) => {
      setQuery(step);
    },
    [setQuery],
  );

  const handleLogout = useCallback(() => {
    dispatch(setTerms(undefined));
    dispatch(logout());
    if (removeTokenCookies()) {
      window.location.reload();
      navigate(LOGIN_ROUTE);
    }
  }, [dispatch, navigate]);

  const content = {
    userProfile: <UserProfile />,
    subscription: <Subscription />,
    properties: <Properties />,
    developments: <Develpments />,
    notifications: <Notifications />,
    messages: <Messages />,
    favourites: <Favourites />,
    admin: <Admin />,
  };

  return (
    <div className={s.wrapper}>
      <div className={s.leftMenu}>
        <ul style={{ listStyle: 'none', marginTop: 32 }}>
          <li className={cn(s.menuItem, { [s.active]: step === 'userProfile' })}>
            <Button
              type="link"
              onClick={() => {
                handleChangeStep('userProfile');
              }}>
              Profile
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: step === 'favourites' })}>
            <Button
              type="link"
              onClick={() => {
                handleChangeStep('favourites');
              }}>
              Favourites
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: step === 'messages' })}>
            <Button
              type="link"
              onClick={() => {
                handleChangeStep('messages');
              }}>
              Messages
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: step === 'properties' })}>
            <Button
              type="link"
              onClick={() => {
                handleChangeStep('properties');
              }}>
              Your Properties
            </Button>
          </li>
          {currentUser?.userType === USER_TYPE_ENUM.DEVELOPER && (
            <li className={cn(s.menuItem, { [s.active]: step === 'properties' })}>
              <Button
                type="link"
                onClick={() => {
                  handleChangeStep('developments');
                }}>
                Your Developments
              </Button>
            </li>
          )}

          <li className={cn(s.menuItem, { [s.active]: step === 'notifications' })}>
            <Button
              type="link"
              onClick={() => {
                handleChangeStep('notifications');
              }}>
              Notifications
            </Button>
          </li>
          <li className={cn(s.menuItem, { [s.active]: step === 'subscription' })}>
            <Button
              type="link"
              onClick={() => {
                handleChangeStep('subscription');
              }}>
              Subscription
            </Button>
          </li>
          {currentUser && currentUser?.roles?.some((role) => role.roleCode === 'ADM') && (
            <li className={cn(s.menuItem, { [s.active]: step === 'admin' })}>
              <Button
                type="link"
                onClick={() => {
                  handleChangeStep('admin');
                }}>
                Admin Page
              </Button>
            </li>
          )}
          <li className={s.logoutItem}>
            <Button type="link" onClick={handleLogout}>
              Log out
            </Button>
          </li>
        </ul>
      </div>
      <div className={s.content}>{content[step!]}</div>
    </div>
  );
};
export default Profile;

import initialApiService from 'services/initialApiService';

import {
  DevelopmentCreateReqType,
  DevelopmentDetailType,
  DevelopmentListResType,
} from 'types/promoters/promoterTypes';
import { uploadImageType, uploadResourceType } from 'types/property/propertyTypes';

const promoterApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    createDevelopment: builder.mutation<DevelopmentDetailType, DevelopmentCreateReqType>({
      query: (data) => ({
        url: `/propertydevelopments/create`,
        method: 'POST',
        body: data,
      }),
    }),
    editDevelopment: builder.mutation<DevelopmentDetailType, DevelopmentCreateReqType>({
      query: (data) => ({
        url: `/propertydevelopments/edit`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteDevelopment: builder.mutation<string, string>({
      query: (id) => ({
        url: `propertydevelopments/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    hideShowDevelopment: builder.mutation<DevelopmentDetailType, { id: string; hide: boolean }>({
      query: (data) => ({
        url: `/propertydevelopments/visibilitystatus`,
        method: 'PUT',
        body: data,
      }),
    }),
    getDevelopmentList: builder.mutation<
      DevelopmentListResType,
      { pageNumber: number; pageSize: number }
    >({
      query: (data) => ({
        url: `/propertydevelopments/list`,
        method: 'POST',
        body: data,
      }),
    }),
    getUserDevelopmentList: builder.mutation<
      DevelopmentListResType,
      { pageNumber: number; pageSize: number }
    >({
      query: (data) => ({
        url: `/users/developments`,
        method: 'POST',
        body: data,
      }),
    }),
    getDevelopmentDetail: builder.query<DevelopmentDetailType, string>({
      query: (id) => ({
        url: `/propertydevelopments/detail/${id}`,
        method: 'GET',
      }),
    }),
    uploadDevelopmentImage: builder.mutation<DevelopmentDetailType, uploadImageType>({
      query: (body) => ({
        url: 'propertydevelopments/image',
        method: 'POST',
        body,
      }),
    }),
    deleteDevelopmentImage: builder.mutation<DevelopmentDetailType, string>({
      query: (id) => ({
        url: `propertydevelopments/image/${id}`,
        method: 'DELETE',
      }),
    }),
    uploadDevelopmentResource: builder.mutation<DevelopmentDetailType, uploadResourceType>({
      query: (body) => ({
        url: 'propertydevelopments/resource',
        method: 'POST',
        body,
      }),
    }),
    deleteDevelopmentResource: builder.mutation<DevelopmentDetailType, string>({
      query: (id) => ({
        url: `propertydevelopments/resource/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCreateDevelopmentMutation,
  useGetUserDevelopmentListMutation,
  useDeleteDevelopmentMutation,
  useHideShowDevelopmentMutation,
  useEditDevelopmentMutation,
  useGetDevelopmentListMutation,
  useLazyGetDevelopmentDetailQuery,
  useUploadDevelopmentImageMutation,
  useDeleteDevelopmentImageMutation,
  useDeleteDevelopmentResourceMutation,
  useUploadDevelopmentResourceMutation,
} = promoterApiService;

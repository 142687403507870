import { Dispatch, FC, SetStateAction, useState } from 'react';

import { Modal, message } from 'antd';
import { Form, TextArea } from 'ui';
import Button from 'ui/button/button';

import { useApproveRejectPropertyMutation } from 'services/properties/propertyApiService';

import { PropertyProcessEnum, PropertyStatusEnum } from 'types/property/propertyEnum';

import s from './RejectModal.module.scss';

interface RejectModalProps {
  isModalOpen?: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  propertyId?: string;
  adminPropertyList?: (requestFilter: {
    pageNumber: number;
    pageSize: number;
    status: PropertyStatusEnum;
  }) => Promise<any>;
  requestFilter?: {
    pageNumber: number;
    pageSize: number;
    status: PropertyStatusEnum;
  };
}

const RejectModal: FC<RejectModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  propertyId,
  adminPropertyList,
}) => {
  const [approveRejectProperty, { isLoading: processing }] = useApproveRejectPropertyMutation();
  const [form] = Form.useForm();
  const [otherText, setOtherText] = useState<string>('');
  const handleOk = () => {
    form
      .validateFields()
      .then(async () => {
        const payload = {
          propertyId: propertyId!,
          status: PropertyProcessEnum.Reject,
          comments: otherText!,
        };
        const res = await approveRejectProperty(payload);

        if ('data' in res) {
          if (adminPropertyList) {
            try {
              await adminPropertyList({
                pageNumber: 1,
                pageSize: 10,
                status: PropertyStatusEnum.Approved,
              });
            } catch (error) {
              console.error('Error fetching property list:', error);
            }
          }
          message.success('Property rejected');
          setOtherText('');
          form.resetFields();
          setIsModalOpen(false);
        }
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo);
      });
  };

  const handleOtherTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherText(e.target.value);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal width={600} centered open={isModalOpen} footer={null} onCancel={handleCancel}>
      <div className={s.wrapper}>
        <div style={{ marginBottom: 8 }}>
          <div style={{ fontSize: 32, fontWeight: 700 }}>Reject property</div>
        </div>
        <Form form={form} layout="vertical">
          <Form.Item
            name="otherDetails"
            rules={[
              { required: true, message: 'Please provide reject reason' },
              { min: 5, message: 'Details must be at least 5 characters' },
            ]}>
            <TextArea
              rows={5}
              className={s.other}
              placeholder="Please provide reason."
              value={otherText}
              onChange={handleOtherTextChange}
              name="otherDetails"
            />
          </Form.Item>

          {/* Buttons */}
          <div className={s.buttonGroup}>
            <Button
              style={{
                width: 160,
                color: '#8E53E8',
                backgroundColor: '#EBDDFF',
              }}
              onClick={() => {
                form.resetFields();
                handleCancel();
              }}>
              CANCEL
            </Button>
            <Button loading={processing} type="primary" style={{ width: 160 }} onClick={handleOk}>
              REJECT
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
export default RejectModal;

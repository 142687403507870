import React, { FC } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getDevelopmentDetailRoute, getEditDevelopmentRoute } from 'routes/property/list';
import { handleCutString } from 'tools/cutstring';
import { Icon, Typography, message } from 'ui';

import {
  useDeleteDevelopmentMutation,
  useGetUserDevelopmentListMutation,
  useHideShowDevelopmentMutation,
} from 'services/promoter/promoterApiService';
import { removeDevelopmentById, setActiveDevelopment } from 'slices/propertySlice';
import { useAppDispatch } from 'store/index';

import { Development } from 'types/promoters/promoterTypes';

// Import Mapbox CSS
import s from './DevCard.module.scss';

const { confirm } = Modal;

interface PropertyCardProps {
  development: Development;
  editable?: boolean;
}

const DevCard: FC<PropertyCardProps> = ({ development, editable }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [hideShowDevelopment] = useHideShowDevelopmentMutation();
  const [getUserDevelopmentList] = useGetUserDevelopmentListMutation();
  const [deleteDevelopment, { isLoading: removing }] = useDeleteDevelopmentMutation();

  const showExportConfirm = async (id: string) => {
    confirm({
      title: 'Would you like to remove this development?',
      icon: <InfoCircleOutlined />,
      content: '',
      centered: true,
      mask: true,
      async onOk() {
        const res = await deleteDevelopment(id);
        if ('data' in res) {
          dispatch(removeDevelopmentById(id));
          message.warning('Development removed!');
          navigate(0);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleHide = async () => {
    const payload = {
      id: development?.id,
      hide: development?.hidden === true ? false : true,
    };
    const res = await hideShowDevelopment(payload);
    if ('data' in res) {
      const criteria = { pageSize: 100, pageNumber: 1 };
      const propertyRes = await getUserDevelopmentList(criteria);
      if ('data' in propertyRes) {
        navigate(0);
      }
    }
  };

  const handleDeleteDevelopment = (id: string) => {
    showExportConfirm(id);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.propertyLink}>
        <div style={{ position: 'relative', textAlign: 'center' }}>
          {development?.mainImageURL ? (
            <div
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                navigate(`${getDevelopmentDetailRoute(development?.id)}`);
              }}>
              <img
                src={development?.mainImageURL || '/assets/images/no-image.svg'}
                alt="property"
                className={s.propertyImage}
              />
            </div>
          ) : (
            <div
              style={{ cursor: 'pointer' }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                navigate(`${getDevelopmentDetailRoute(development?.id)}`);
              }}>
              <img src="/assets/images/no-image.svg" alt="property" className={s.propertyImage} />
            </div>
          )}
          {editable && (
            <>
              {' '}
              <Button className={s.hideShowButton} onClick={handleHide}>
                <span
                  style={{
                    display: 'inline-block',
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: development?.hidden !== true ? '#02C29A' : '#FF4B53',
                    marginRight: 8,
                  }}></span>
                {development?.hidden !== true ? 'AVAILABLE' : 'NOT AVAILABLE'}
              </Button>
              <Button
                className={s.editButton}
                onClick={() => {
                  dispatch(setActiveDevelopment(undefined));
                  navigate(`${getEditDevelopmentRoute(development?.id)}`);
                }}>
                <Icon name="edit" />
              </Button>
              <Button
                className={s.deleteButton}
                disabled={removing}
                type="link"
                onClick={() => handleDeleteDevelopment(development?.id)}>
                <Icon name="trashBox" size={14} />
              </Button>{' '}
            </>
          )}
          <Button className={s.imageCount} type="link">
            <Icon name="camera" size={14} />
            {development?.imageCount}
          </Button>
        </div>
        {/* <Button onClick={() => navigate(`${getPropertyDetailRoute(property?.id)}`)}>Preview</Button> */}

        <Typography type="h5" className={s.PropertyName}>
          {handleCutString(development?.name || '', 20, true)}
        </Typography>
        <div className={s.cardFooter}></div>
      </div>
    </div>
  );
};

export default DevCard;

import React, { FC, useState } from 'react';

import { Modal } from 'antd';
import ImageGallery from 'react-image-gallery';

import s from './ImageGrid.module.scss';

interface ImageGridType {
  images: {
    original: string;
    thumbnail: string;
    originalHeight?: number;
    originalWidth?: number;
  }[];
}

const ImageGrid: FC<ImageGridType> = ({ images }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!images || images.length === 0) return null;

  const leftImages = images.slice(1, 3);
  const rightImage = images[0];
  const bottomImages = images.slice(4);

  const remainingCount = images.length - 7;
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <div
        className={`${s.imageGrid} ${leftImages.length === 0 ? s.noLeftImages : ''} ${
          leftImages.length === 1 ? s.oneLeftImages : ''
        }`}>
        <div className={s.leftColumn}>
          {leftImages.map((img, index) => (
            <div
              key={index}
              className={`${s.leftImageContainter} ${
                leftImages.length === 1 ? s.oneLeftImageContainer : ''
              }`}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
              onClick={showModal}>
              <img src={img.original} alt={`Left ${index}`} className={s.leftImage} />
            </div>
          ))}
        </div>

        {rightImage && (
          <div
            className={s.rightImageContainter}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={showModal}>
            <img src={rightImage.original} alt="Right" className={s.rightImage} />
          </div>
        )}
      </div>

      {bottomImages.length > 0 && (
        <div className={s.bottomRow}>
          {/* Iterate through the bottomImages and fill up to 4 items */}
          {[
            ...bottomImages.slice(0, 4), // Take up to 4 items
            ...bottomImages.slice(0, Math.max(0, 4 - bottomImages.length)), // Fill the remaining slots from the start
          ].map((img, index) =>
            index === 3 && remainingCount > 0 ? (
              <div
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                onClick={showModal}
                key={index}
                className={s.bottomImageContainer}>
                <img src={img.original} alt={`Bottom ${index}`} className={s.bottomLastImage} />
                <div className={s.overlay}>
                  <span className={s.overlayText}>+{remainingCount}</span>
                </div>
              </div>
            ) : (
              <div
                onKeyDown={() => {}}
                role="button"
                tabIndex={0}
                onClick={showModal}
                key={index}
                className={s.btmImageContainer}>
                <img src={img.original} alt={`Bottom ${index}`} />
              </div>
            ),
          )}
        </div>
      )}

      <Modal
        width={700}
        centered
        title={<div style={{ fontSize: 36, fontWeight: 700 }}>More images</div>}
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}>
        {images && <ImageGallery showFullscreenButton={false} items={images} />}
      </Modal>
    </div>
  );
};

export default ImageGrid;

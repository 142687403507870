import React, { useCallback } from 'react';

import { Button } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
import { Icon, message } from 'ui';

import { useDeletePropertyImageMutation } from 'services/properties/propertyApiService';
import { setActiveProperty } from 'slices/propertySlice';
import { useAppDispatch } from 'store/index';

import { PropertyType } from 'types/property/propertyTypes';

import s from './PostProperty.module.scss';

const Card = ({ src, id, index, moveImage, description }) => {
  const dispatch = useAppDispatch();
  const [deletePropertyImage, { isLoading: removing }] = useDeletePropertyImageMutation();

  const handleDeleteMedia = useCallback(
    async (id: string) => {
      const res = await deletePropertyImage(id);
      if ('data' in res) {
        message.warning('Image removed!');
        dispatch(setActiveProperty(res.data as PropertyType));
      }
    },
    [dispatch, deletePropertyImage],
  );
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [, drop] = useDrop({
    accept: 'image',
    hover: (item: any, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset ? clientOffset.y - hoverBoundingRect.top : 0;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveImage(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} className={s.uploadedFile} style={{ opacity }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {src.endsWith('.mp4') ? (
          <video width={64} height={40}>
            <source src={src} type="video/mp4" />
            <track
              kind="captions"
              src="path-to-captions.vtt"
              srcLang="en"
              label="English"
              default
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img className={s.uploadedImage} src={src} width={120} height={80} alt={description} />
        )}
      </div>
      <Button
        disabled={removing}
        className={s.deleteButton}
        type="link"
        onClick={() => handleDeleteMedia(id)}>
        <Icon name="trashBox" size={14} />
      </Button>
    </div>
  );
};

export default Card;

import { FC } from 'react';

import { PhoneFilled } from '@ant-design/icons';
import { getAgencyRoute } from 'routes/user-management/list';
import { NavLink, Typography } from 'ui';

import { UserTypeEnum } from 'types/property/propertyEnum';

import s from './AgentCard.module.scss';

interface AgentCardProps {
  userId?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  profileImageURL?: string;
  userProfileImageURL?: string;
  showMobile?: boolean;
  agencyId?: string;
  userType?: UserTypeEnum;
}

const AgentCard: FC<AgentCardProps> = ({
  firstName,
  lastName,
  mobileNumber,
  profileImageURL,
  userProfileImageURL,
  showMobile,
  agencyId,
  userType,
}) => {
  const url = getAgencyRoute(agencyId ?? '');
  return (
    <div className={s.wrapper}>
      <div className={s.imageWrapper}>
        <img
          src={
            profileImageURL ===
            'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/'
              ? userProfileImageURL
              : userProfileImageURL !==
                'https://onthatstreet.blob.core.windows.net/content/users/profilepictures/'
              ? userProfileImageURL
              : '/assets/images/avatar/avatar.svg'
          }
          width={50}
          height={50}
          style={{ borderRadius: '50%' }}
          alt="agent"
        />
        <div>
          <Typography type="h4">
            {firstName} {lastName}
          </Typography>

          <Typography type="label"> {userType}</Typography>
        </div>
      </div>
      <div className={s.infoWrapper}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 12,
          }}>
          <div className={s.agenciesImage}>
            <PhoneFilled style={{ fontSize: 20, color: '#CED2E5' }} />
            {!showMobile && (
              <Typography type="h6" weight={700}>
                {mobileNumber}
              </Typography>
            )}
          </div>
          {agencyId && (
            <NavLink
              label="View Agency Detail"
              to={url}
              className={s.AgencyLink}
              typographyType="main"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default AgentCard;

import { FC, useCallback, useEffect, useState } from 'react';

import { Radio, Select, SelectProps, Space } from 'antd';
import { Button as AntButton } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import PhoneInput from 'react-phone-input-2';
import mt from 'react-phone-input-2';
import { useNavigate, useParams } from 'react-router-dom';
import { PROFILE_ROUTE } from 'routes/user-management/list';
import { Button, Form, Icon, Input, Label, Typography, message } from 'ui';
import PropertyCard from 'ui/propertyCard';
import ScrollToTopButton from 'ui/scrollToTop';
import { formRules } from 'utils/formRules';

import { activeDevelopmentSelector, propertyListSelector } from 'selectors/propertySlice.selector';
import {
  useCreateDevelopmentMutation,
  useDeleteDevelopmentImageMutation,
  useDeleteDevelopmentResourceMutation,
  useEditDevelopmentMutation,
  useLazyGetDevelopmentDetailQuery,
  useUploadDevelopmentImageMutation,
  useUploadDevelopmentResourceMutation,
} from 'services/promoter/promoterApiService';
import { setActiveDevelopment, updateDevelopments } from 'slices/propertySlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import s from './CreateDevelopment.module.scss';
import DevLocationVenue from './dev-location-venue';
import { PrepareDevLocationVenueResult } from './dev-location-venue/devLocationVenue.utils';

const CreateDevelopment: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { developmentId } = useParams();
  const properties = useAppSelector(propertyListSelector);
  const activeDevelopment = useAppSelector(activeDevelopmentSelector);

  const [createDevelopment, { isLoading: creating }] = useCreateDevelopmentMutation();
  const [editDevelopment, { isLoading: editing }] = useEditDevelopmentMutation();
  const [getDevelopmentDetail, { isFetching }] = useLazyGetDevelopmentDetailQuery();
  const [uploadDevelopmentImage] = useUploadDevelopmentImageMutation();
  const [uploadDevelopmentResource] = useUploadDevelopmentResourceMutation();
  const [deleteDevelopmentImage, { isLoading: removing }] = useDeleteDevelopmentImageMutation();
  const [deleteDevelopmentResource, { isLoading: docRemoving }] =
    useDeleteDevelopmentResourceMutation();

  const [loading, setLoading] = useState<string>('');
  const [resourceLoading, setResourceLoading] = useState<string>('');
  const [locationData, setLocationData] = useState<PrepareDevLocationVenueResult>();
  const [selectedValues, setSelectedValues] = useState<string[] | undefined>([]);

  const selectedProperties = properties?.filter((property) =>
    selectedValues?.includes(property.id),
  );

  const customRequest = async (options) => {
    const { file, onError } = options;

    try {
      const toBase64 = (file: File) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });

      const fileBase64 = await toBase64(file);

      // Create a payload with the base64 string and other fields

      const payload = {
        id: activeDevelopment?.id,
        base64Image: fileBase64,
        fileName: file.name,
        description: file.name,
        isMain: true,
      };
      const res = await uploadDevelopmentImage(payload);

      if ('data' in res) {
        message.success('Image successfully uploaded!');
        setLoading('done');
        dispatch(setActiveDevelopment(res?.data));
      }
    } catch (error) {
      setLoading('done');
      onError(error);
    }
  };

  const customDocRequest = async (options) => {
    const { file, onError } = options;

    try {
      const toBase64 = (file: File) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        });

      const fileBase64 = await toBase64(file);

      // Create a payload with the base64 string and other fields

      const payload = {
        id: activeDevelopment?.id,
        base64File: fileBase64,
        fileName: file.name,
        name: file.name,
      };
      const res = await uploadDevelopmentResource(payload);

      if ('data' in res) {
        message.success('Resource file successfully uploaded!');
        setResourceLoading('done');
        dispatch(setActiveDevelopment(res.data));
      }
    } catch (error) {
      setResourceLoading('done');
      onError(error);
    }
  };

  const handleDeleteMedia = useCallback(
    async (id: string) => {
      const res = await deleteDevelopmentImage(id);
      if ('data' in res) {
        message.warning('Image removed!');
        dispatch(setActiveDevelopment(res?.data));
      }
    },
    [deleteDevelopmentImage, dispatch],
  );

  const handleDeleteResource = useCallback(
    async (id: string) => {
      const res = await deleteDevelopmentResource(id);
      if ('data' in res) {
        message.warning('Resource file removed!');
        dispatch(setActiveDevelopment(res?.data));
      }
    },
    [dispatch, deleteDevelopmentResource],
  );

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      if (!locationData?.latLng?.lng || !locationData?.latLng?.lat) {
        message.error('Location coordinates are required.');
        return;
      }

      const payload = {
        ...values,
        id: developmentId || undefined, // Include ID only when editing
        coordinates: {
          longitude: locationData.latLng.lng,
          latitude: locationData.latLng.lat,
        },
      };

      const res = developmentId
        ? await editDevelopment(payload) // Edit if developmentId exists
        : await createDevelopment(payload); // Create otherwise

      if ('data' in res) {
        form.resetFields();
        message.success(`Successfully ${developmentId ? 'updated' : 'created'} development`);
        dispatch(setActiveDevelopment(res?.data));
        const tempData = {
          id: res?.data?.id,
          name: res?.data?.name,
          websiteURL: res?.data?.websiteURL,
          hidden: res?.data?.hidden,
          imageCount: res?.data?.imageCount,
          mainImageURL: res?.data?.mainImageURL,
          email: res?.data?.email,
          facebookLink: res?.data?.facebookLink,
          phone: res?.data?.phone,
          coordinates: res?.data?.coordinates,
          finishStatus: res?.data?.finishStatus,
        };
        dispatch(updateDevelopments(tempData));
        navigate(`${PROFILE_ROUTE}?step=developments`);
      } else {
        message.error(`Failed to ${developmentId ? 'update' : 'create'} development`);
      }
    } catch (error) {
      message.error('Please fill in the required fields.');
    }
  };

  console.log(properties?.filter((property) => property?.isEditable === true));

  const propertyOptions: SelectProps['options'] = properties
    ?.filter((property) => property?.isEditable === true)
    ?.map((item) => ({
      label: item.name,
      value: item?.id,
    }));

  const handleGetDevelopment = useCallback(
    async (developmentId: string) => {
      const res = await getDevelopmentDetail(developmentId);

      if ('data' in res) {
        form.setFieldValue('name', res?.data?.name);
        form.setFieldValue(['address', 'street'], res?.data?.address?.street);
        form.setFieldValue(['address', 'postcode'], res?.data?.address?.postCode);
        form.setFieldValue(['address', 'town'], res?.data?.address?.town);
        form.setFieldValue('propertyIds', res?.data?.properties?.map((item) => item.id) || []);
        setSelectedValues(res?.data?.properties?.map((item) => item.id));
        setLocationData({
          latLng: {
            lat: res?.data?.coordinates?.latitude!,
            lng: res?.data?.coordinates?.longitude!,
          },
        });
        form.setFieldValue('email', res?.data?.email);
        form.setFieldValue('facebookLink', res?.data?.facebookLink);
        form.setFieldValue('phone', res?.data?.phone);
        form.setFieldValue('description', res?.data?.description);
        form.setFieldValue('finishStatus', res?.data?.finishStatus);

        dispatch(setActiveDevelopment(res?.data));
      }
    },
    [form, getDevelopmentDetail, dispatch],
  );

  useEffect(() => {
    if (developmentId) {
      handleGetDevelopment(developmentId);
    }
  }, [developmentId, handleGetDevelopment]);

  useEffect(() => {
    if (!activeDevelopment) {
      form.resetFields();
    }
  }, [form, activeDevelopment]);

  return (
    <div className={s.wrapper}>
      <ScrollToTopButton />
      <Typography type="h2">Your Development</Typography>
      <Form className={s.form} form={form} disabled={isFetching} onFinish={handleSubmit}>
        <div className={s.devItem}>
          <Typography type="h5">Development Name</Typography>
          <div>
            <Form.Item name="name" rules={[formRules.required]}>
              <Input name="name" autoComplete="off" style={{ backgroundColor: 'white' }} />
            </Form.Item>
          </div>
        </div>
        <div className={s.devItemStart}>
          <Typography type="h5">Location</Typography>
          <div>
            {(locationData || !developmentId) && (
              <DevLocationVenue locationData={locationData} setLocationData={setLocationData} />
            )}
          </div>
        </div>
        <div className={s.devItemStart}>
          <Typography type="h5">Properties</Typography>
          <div className={s.dropDownWrapper}>
            <Form.Item name="propertyIds">
              <Select
                size="large"
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select properties"
                showSearch
                optionFilterProp="label" // Ensures filtering is based on the label
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                }
                onChange={(values) => setSelectedValues(values)}
                options={propertyOptions}
              />
            </Form.Item>
            <div className={s.cardsWrapper}>
              {selectedProperties?.map((item, index) => (
                <PropertyCard key={index} property={item} editable={false} />
              ))}
            </div>
          </div>
        </div>
        <div className={s.devItem}>
          <Typography type="h5">Contacts</Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            <div style={{ width: '100%' }}>
              <Label>Email</Label>
              <Form.Item name="email" validateFirst rules={[formRules.required, formRules.email]}>
                <Input name="email" autoComplete="off" style={{ backgroundColor: 'white' }} />
              </Form.Item>
            </div>
            <div style={{ width: '100%' }}>
              <Label>Website URL</Label>
              <Form.Item name="websiteURL">
                <Input name="websiteURL" autoComplete="off" style={{ backgroundColor: 'white' }} />
              </Form.Item>
            </div>
            <div style={{ width: '100%' }}>
              <Label>Phone</Label>
              <Form.Item className={s.phoneContainer} name="phone" rules={[formRules.required]}>
                <PhoneInput
                  containerClass={s.phoneContainer}
                  inputClass={s.phone}
                  buttonClass={s.phoneButton}
                  regions="europe"
                  preferredCountries={['mt']}
                  country="mt"
                  localization={mt}
                  inputProps={{ name: 'phone' }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className={s.devItem}>
          <Typography type="h5">Development information</Typography>
          <Form.Item name="description">
            <TextArea className={s.comments} name="description" rows={7} />
          </Form.Item>
        </div>
        <div className={s.devItemStart}>
          <Typography type="h5">Development Status</Typography>
          <Form.Item name="finishStatus" initialValue="finished">
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="finished">Finished</Radio>
                <Radio value="partlyFinished">Partly Finished</Radio>
                <Radio value="furnished">Furnished</Radio>
                <Radio value="partlyFurnished">Partly Furnished</Radio>
                <Radio value="shell">Shell</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>
        <div style={{ marginTop: 24 }}>
          <div className={s.uploadedFiles}>
            {activeDevelopment?.images?.length! > 0 && (
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <Typography type="h5">PHOTOS</Typography>
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', gap: 8, overflowX: 'auto' }}>
              {activeDevelopment?.images?.map((file) => (
                <div key={file.id} className={s.uploadedFile}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    {file.imageURL.endsWith('.mp4') ? (
                      <video width={64} height={40}>
                        <source src={file.imageURL} type="video/mp4" />
                        <track
                          kind="captions"
                          src="path-to-captions.vtt"
                          srcLang="en"
                          label="English"
                          default
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img
                        className={s.uploadedImage}
                        src={file.imageURL}
                        width={120}
                        height={80}
                        alt="uploadedImage"
                      />
                    )}
                  </div>
                  <AntButton
                    disabled={removing}
                    className={s.deleteButton}
                    type="link"
                    onClick={() => handleDeleteMedia(file.id)}>
                    <Icon name="trashBox" size={14} />
                  </AntButton>
                </div>
              ))}
            </div>
            {loading && (
              <div className={s.fileUpload}>
                <div
                  className={`${s.fileContent} ${loading === 'uploading' ? s.fill : ''} ${
                    loading === 'done' ? s.done : ''
                  }`}
                />
              </div>
            )}
          </div>
          <div className={s.uploadWrapper}>
            <Typography type="h5" weight={700}>
              Images
            </Typography>
            <div className={s.upload}>
              <Dragger
                disabled={!activeDevelopment?.id || loading === 'uploading'}
                hasControlInside={true}
                customRequest={customRequest}
                accept=".jpg, .jpeg, .png, .gif, .mp4 .webp"
                maxCount={1}
                showUploadList={false}
                beforeUpload={(file) => {
                  const isImageOrVideo = file.type.startsWith('image/');
                  // setFileType(file.type.startsWith('image/') ? 'image' : 'video');
                  if (!isImageOrVideo) {
                    message.error('Only image files can be uploaded!');
                  }
                  return isImageOrVideo;
                }}
                onChange={(info) => {
                  if (info.file.status === 'uploading') {
                    setLoading('uploading');
                    return;
                  }
                  if (info.file.status === 'done') {
                    message.success(`${info.file.name} successfully uploaded!`);
                    setLoading('done');
                  } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} upload failed.`);
                  }
                }}>
                <div className={s.uploadBlock}>
                  <Button
                    disabled={!activeDevelopment?.id}
                    htmlType="button"
                    loading={loading === 'uploading'}
                    className={s.chooseButton}>
                    CHOOSE FILES
                  </Button>
                  <Typography type="main" color="grey">
                    or drag PNG, JPEG or WEBP less than 5MB
                  </Typography>
                </div>
              </Dragger>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 36 }}>
          <div className={s.uploadedFiles}>
            {activeDevelopment?.resources?.length! > 0 && (
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <Typography type="h5">FILES</Typography>
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', gap: 16, overflowX: 'auto' }}>
              {activeDevelopment?.resources?.map((file) => (
                <div key={file.id} className={s.uploadedDocFile}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <Icon name="file" />
                    <span>{file.name}</span>
                  </div>
                  <AntButton
                    disabled={docRemoving}
                    className={s.deleteDocButton}
                    type="link"
                    onClick={() => handleDeleteResource(file.id)}>
                    <Icon name="trashBox" size={14} />
                  </AntButton>
                </div>
              ))}
            </div>
            {resourceLoading && (
              <div className={s.fileUpload}>
                <div
                  className={`${s.fileContent} ${resourceLoading === 'uploading' ? s.fill : ''} ${
                    resourceLoading === 'done' ? s.done : ''
                  }`}
                />
              </div>
            )}
          </div>
          <div className={s.uploadWrapper}>
            <Typography type="h5" weight={700}>
              Files
            </Typography>
            <div className={s.upload}>
              <Dragger
                disabled={!activeDevelopment?.id || resourceLoading === 'uploading'}
                hasControlInside={true}
                customRequest={customDocRequest}
                accept=".doc, .pdf, 'docx"
                maxCount={1}
                showUploadList={false}
                beforeUpload={(file) => {
                  const isDoc =
                    file.type.endsWith('/doc') ||
                    file.type.endsWith('/pdf') ||
                    file.type.endsWith('/docx');
                  // setFileType(file.type.startsWith('image/') ? 'image' : 'video');
                  if (!isDoc) {
                    message.error('Only doc files can be uploaded!');
                  }
                  return isDoc;
                }}
                onChange={(info) => {
                  if (info.file.status === 'uploading') {
                    setResourceLoading('uploading');
                    return;
                  }
                  if (info.file.status === 'done') {
                    message.success(`${info.file.name} successfully uploaded!`);
                    setResourceLoading('done');
                  } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} upload failed.`);
                  }
                }}>
                <div className={s.uploadBlock}>
                  <Button
                    disabled={!activeDevelopment?.id}
                    htmlType="button"
                    loading={resourceLoading === 'uploading'}
                    className={s.chooseButton}>
                    CHOOSE FILES
                  </Button>
                  <Typography type="main" color="grey">
                    or drag DOC, DOCX or PDF less than 5MB
                  </Typography>
                </div>
              </Dragger>
            </div>
          </div>
        </div>

        <div className={s.Submit}>
          <Button
            loading={creating || editing}
            style={{ width: 300, height: 50 }}
            type="primary"
            htmlType="submit">
            {activeDevelopment?.id ? 'SAVE' : 'CREATE'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CreateDevelopment;

import { FC, useCallback, useEffect, useState } from 'react';

import { Button, Select, Space, Table, TableProps } from 'antd';
import Pagination from 'component/pagination';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { getPropertyDetailRoute } from 'routes/property/list';
import { setTokenCookies } from 'tools/cookieTools';
import { Tabs, TabsProps, Typography, message } from 'ui';

import {
  useAdminPropertyListMutation,
  useApproveRejectPropertyMutation,
} from 'services/properties/propertyApiService';
import { userListType } from 'services/user-management/userManagement.api.type';
import {
  useLazyGetCurrentUserQuery,
  useListUserMutation,
  useLoginAsUserMutation,
} from 'services/user-management/userManagementApiService';

import { PropertyProcessEnum, PropertyStatusEnum } from 'types/property/propertyEnum';
import { GetPropertyType } from 'types/property/propertyTypes';

import s from './Admin.module.scss';
import AdminUserListAction from './admin-user-list-action';
import RejectModal from './rejectModal/rejectModal';

type ColumnsType<T extends object> = TableProps<T>['columns'];

const initialFilter = {
  pageNumber: 1,
  pageSize: 10,
  status: PropertyStatusEnum.Approved,
};

const initialUserCriteria = {
  pageNumber: 1,
  pageSize: 10,
};

const statusFilterOption = [
  {
    value: PropertyStatusEnum.Approved,
    label: 'Approved',
  },
  {
    value: PropertyStatusEnum.Blocked,
    label: 'Blocked',
  },
  {
    value: PropertyStatusEnum.Submitted,
    label: 'Submitted',
  },
  {
    value: PropertyStatusEnum.Hidden,
    label: 'Hidden',
  },
  {
    value: PropertyStatusEnum.Premium,
    label: 'Premium',
  },
];

const Admin: FC = () => {
  const navigate = useNavigate();
  const [adminPropertyList, { isLoading, data: data }] = useAdminPropertyListMutation();
  const [approveRejectProperty, { isLoading: processing }] = useApproveRejectPropertyMutation();
  const [getUserList, { isLoading: fetchingUser, data: userList }] = useListUserMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [loginAsUser] = useLoginAsUserMutation();
  const [userCriteria, setUserCriteria] = useState<{ pageNumber: number; pageSize: number }>(
    initialUserCriteria,
  );
  const [requestFilter, setRequestFilter] = useState<{
    pageNumber: number;
    pageSize: number;
    status: PropertyStatusEnum;
  }>(initialFilter);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<string>();
  const [currentId, setCurrentId] = useState<string>();
  const showedElements =
    Number(data?.properties?.length) +
    (Number(data?.pageNumber) - 1) * Number(requestFilter.pageSize);

  const handleStatusChange = (value: string) => {
    setStatusFilter(value);
    setRequestFilter((prevFilter) => ({
      ...prevFilter,
      pageNumber: 1,
      status: value as PropertyStatusEnum,
    }));
  };

  useEffect(() => {
    const handleFetchUserList = () => {
      if (userCriteria) {
        getUserList(userCriteria);
      }
    };
    handleFetchUserList();
  }, [getUserList, userCriteria]);

  const formatPrice = (value: number): string => {
    if (value >= 1_000_000) {
      return `€${(value / 1_000_000).toFixed(1)} M`;
    } else if (value >= 1_000) {
      return `€${(value / 1_000).toFixed(1)} K`;
    } else {
      return `€${value}`;
    }
  };

  const handleProcessProperty = useCallback(
    async (id: string, status: PropertyProcessEnum) => {
      try {
        const payload = {
          propertyId: id,
          status: status,
        };

        const res = await approveRejectProperty(payload);

        if ('data' in res) {
          message.success('Successfully approved');

          adminPropertyList(requestFilter);
        }
      } catch (error) {
        console.error('Error processing property:', error);
      }
    },
    [approveRejectProperty, adminPropertyList, requestFilter],
  );

  const handleSelect = (key: string, email: string) => {
    loginAsUser({ email }).then((data) => {
      if ('data' in data) {
        setTokenCookies(data.data.accessToken, data.data.refreshToken);
        getCurrentUser().then((data) => {
          if ('data' in data) {
            navigate('/');
          }
        });
      }
    });
  };

  useEffect(() => {
    if (requestFilter) {
      adminPropertyList(requestFilter);
    }
  }, [requestFilter, adminPropertyList]);

  const columns: ColumnsType<GetPropertyType> = [
    {
      title: 'Image',
      dataIndex: 'imageURL',
      key: 'imageURL',
      render: (item) => (
        <>
          {item ? (
            <div style={{ cursor: 'pointer' }} role="button" tabIndex={0} onKeyDown={() => {}}>
              <img src={item} alt="property" className={s.propertyImage} />
            </div>
          ) : (
            <div style={{ cursor: 'pointer' }} role="button" tabIndex={0} onKeyDown={() => {}}>
              <img src="/assets/images/no-image.svg" alt="property" className={s.propertyImage} />
            </div>
          )}
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => <div> {formatPrice(price)}</div>,
    },
    {
      title: 'Parameters',
      dataIndex: 'featuredParameters',
      key: 'featuredParameters',
      render: (item) => (
        <div>
          {item?.map((param) => `${param.value} ${param.shortenedCaption}`).join(' • ')} •{' '}
          {item.totalSize}м²
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (item) => (
        <Space size="middle">
          <div className={s.actionButtons}>
            <Button
              onClick={() => {
                navigate(`${getPropertyDetailRoute(item?.id)}`);
              }}
              type="link"
              size="small">
              View Details
            </Button>
            <Button
              type="link"
              size="small"
              disabled={item?.status === PropertyStatusEnum.Approved}
              loading={processing}
              onClick={() => handleProcessProperty(item?.id, PropertyProcessEnum.Approve)}>
              Approve
            </Button>
            {item?.status !== PropertyStatusEnum.Blocked && (
              <>
                <Button
                  type="link"
                  size="small"
                  loading={processing}
                  onClick={() => {
                    setIsRejectModalOpen(true);
                    setCurrentId(item?.id);
                  }}>
                  Reject
                </Button>
              </>
            )}
          </div>
        </Space>
      ),
    },
  ];

  const userTableColumns: ColumnsType<userListType> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName', // Keep dataIndex if sorting/filtering is used, otherwise it's optional
      key: 'name',
      render: (_, record) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Register Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dayjs(createdAt).format('YYYY-MM-DD'),
    },
    {
      title: 'Action',
      render: (row) => <AdminUserListAction handleSelect={(key) => handleSelect(key, row.email)} />,
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Property Management',
      children: (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 12, marginBottom: 16 }}>
            <b>Filter:</b>
            <Select
              className={s.rentSelect}
              defaultValue={PropertyStatusEnum.Approved}
              value={statusFilter}
              onChange={handleStatusChange}
              options={statusFilterOption}></Select>
          </div>
          {data && (
            <Table
              columns={columns}
              dataSource={data?.properties}
              pagination={false}
              loading={isLoading}
            />
          )}
          <Pagination
            handlePageChange={(page) =>
              setRequestFilter((prevValue) => ({ ...prevValue, pageNumber: page }))
            }
            showedElements={showedElements}
            totalElements={data?.totalRecords}
            total={data?.totalPages}
            currentPage={data?.pageNumber || 1}
            handleSizeChange={(size) =>
              setRequestFilter((prevValue) => ({ ...prevValue, pageNumber: 1, pageSize: size }))
            }
            defaultPageSize={10}
          />
        </div>
      ),
    },
    {
      key: '2',
      label: 'User Management',
      children: (
        <div>
          <div style={{ marginTop: 16 }}>
            <Table
              dataSource={userList?.users}
              columns={userTableColumns}
              loading={fetchingUser}
              pagination={false}
            />
            <Pagination
              handlePageChange={(page) =>
                setUserCriteria((prevValue) => ({ ...prevValue, pageNumber: page }))
              }
              showedElements={showedElements}
              totalElements={userList?.totalRecords}
              total={userList?.totalPages}
              currentPage={userList?.pageNumber || 1}
              handleSizeChange={(size) =>
                setUserCriteria((prevValue) => ({ ...prevValue, pageNumber: 1, pageSize: size }))
              }
              defaultPageSize={10}
            />
          </div>
        </div>
      ),
    },
    // {
    //   key: '3',
    //   label: 'Analytics & Reports',
    //   children: <div></div>,
    // },
    // {
    //   key: '4',
    //   label: 'Settings & Configurations',
    //   children: <div></div>,
    // },
  ];
  return (
    <div className={s.wrapper}>
      <Typography className={s.title}>Admin Page</Typography>
      <Tabs style={{ marginTop: 24 }} items={items} />
      <RejectModal
        isModalOpen={isRejectModalOpen}
        propertyId={currentId!}
        setIsModalOpen={setIsRejectModalOpen}
        requestFilter={requestFilter}
        adminPropertyList={adminPropertyList}
      />
    </div>
  );
};
export default Admin;

import { createContext } from 'react';

import { FormInstance } from 'ui';

import {
  CommercialTypeEnum,
  GroundRentTypeEnum,
  OrientationTypeEnum,
  PostingTypeEnum,
  SaleStatusEnum,
} from 'types/property/propertyEnum';

import { PrepareDevLocationVenueResult } from './dev-location-venue/devLocationVenue.utils';

export const statusOption = [
  { label: 'AVAILABLE', value: SaleStatusEnum.Available },
  { label: 'NOT AVAILABLE', value: SaleStatusEnum.Sold },
];

export const propertyTypeOption = [
  { label: 'RESIDENTIAL', value: CommercialTypeEnum.Residential },
  { label: 'COMMERCIAL', value: CommercialTypeEnum.Commercial },
];

export const contactVisibilityTypeOption = [
  { label: 'PUBLIC', value: 'public' },
  { label: 'PRIVATE', value: 'private' },
];

export const postTypeOption = [
  { label: 'SALE', value: PostingTypeEnum.Sale },
  { label: 'SHARE', value: PostingTypeEnum.Share },
  { label: 'RENT', value: PostingTypeEnum.Rent },
];

export const orientationType = [
  { label: 'NORTH', value: OrientationTypeEnum.North },
  { label: 'EAST', value: OrientationTypeEnum.East },
  { label: 'SOUTH', value: OrientationTypeEnum.South },
  { label: 'WEST', value: OrientationTypeEnum.West },
];

export const groundRentTypeOption = [
  { label: 'FREEHOLD', value: GroundRentTypeEnum.FreeHold },
  { label: 'GROUND RENT', value: GroundRentTypeEnum.GroundRent },
];

export type HandleChangeType = (
  changedValues: Partial<Record<string, PrepareDevLocationVenueResult>>,
  allValues: FormInstance['getFieldsValue'],
) => void;

interface createPropertyContextInterface {
  form?: FormInstance;
  onChangeForm?: HandleChangeType;
}

export const createPropertyContext = createContext<createPropertyContextInterface>({});

export const getVicinity = async (lat: number, lon: number): Promise<string | null> => {
  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAP_BOX_TOKEN; // Replace with your token

  try {
    const response = await fetch(
      `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${lon}&latitude=${lat}&access_token=${MAPBOX_ACCESS_TOKEN}`,
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    const features = data.features;
    if (features && features.length > 0) {
      // Prioritize 'address' or 'street' type, otherwise use the first result
      const detailedFeature =
        features.find((f: any) => f?.properties?.feature_type?.includes('place')) || features[0];

      const placeName = detailedFeature?.properties?.full_address;
      const parts = placeName.split(',');

      return parts.length > 1 ? parts[0] : placeName;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching vicinity:', error);
    return null;
  }
};

import { FC, useEffect } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Spin } from 'antd';
import classNames from 'classnames';
import { Typography } from 'ui';
import DevCard from 'ui/devCard';

import { useGetUserDevelopmentListMutation } from 'services/promoter/promoterApiService';

import s from './Developments.module.scss';

const Develpments: FC = () => {
  const [getUserDevelopmentList, { data, isLoading }] = useGetUserDevelopmentListMutation();

  useEffect(() => {
    const payload = { pageNumber: 1, pageSize: 200 };
    getUserDevelopmentList(payload);
  }, [getUserDevelopmentList]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <Typography>Your Developments</Typography>
      </div>

      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 40,
          }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : data?.developments?.length ? (
        <div className={classNames(s.components)}>
          {data.developments.map((item, index) => (
            <DevCard key={index} editable={true} development={item} />
          ))}
        </div>
      ) : (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          style={{ height: 150 }}
          description={<Typography type="h3">No developments</Typography>}
          className={s.empty}
        />
      )}
    </div>
  );
};
export default Develpments;

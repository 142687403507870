import { FC } from 'react';

import { Button, Icon, Label, Typography } from 'ui';

import s from './Pricing.module.scss';

const Pricing: FC = () => {
  return (
    <div className={s.wrapper}>
      <Typography type="h1" weight={900}>
        Property Listing Pricing
      </Typography>

      <div className={s.content}>
        <Typography type="h4" className={s.comment} weight={500}>
          No commission, no contract, simply pay to list your properties.<br></br> For owners,
          agents, buyers and tenants. To sell, rent or share.
        </Typography>
        <div className={s.itemWrapper}>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="clone" size={40} />
            </div>
            <Typography type="h6">Easily create your listing</Typography>
          </div>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="send" color="#8E53E8" size={40} />
            </div>
            <Typography type="h6">Add your contact details or your agents’</Typography>
          </div>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="map" size={40} />
            </div>
            <Typography type="h6">Your property will appear on our map</Typography>
          </div>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="mail" size={40} />
            </div>
            <Typography type="h6">Email support</Typography>
          </div>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="heart" color="#8E53E8" size={40} />
            </div>
            <Typography type="h6">Share your property on social media</Typography>
          </div>
          <div className={s.item}>
            <div className={s.iconWrapper}>
              <Icon name="star" size={40} />
            </div>
            <Typography type="h6">Be the first to use our new beta features</Typography>
          </div>
        </div>
        <div className={s.pricingCardWrapper}>
          <div className={s.pricingCard}>
            <Typography type="h5" weight={800}>
              Free
            </Typography>
            <Typography type="h5">
              Your first <br></br> listing
            </Typography>
            <Label> </Label>
            <Button style={{ fontSize: 12 }} fullWidth>
              SIGN UP
            </Button>
          </div>
          <div className={s.pricingCard}>
            <Typography type="h5" weight={800}>
              1 Listing
            </Typography>
            <Typography type="h5">
              €30 <br></br>3 months
            </Typography>
            <Label>Billed €30 </Label>
            <Button style={{ fontSize: 12 }} fullWidth>
              SIGN UP
            </Button>
          </div>
          <div className={s.pricingCard}>
            <Typography type="h5" weight={800}>
              Up to 9 Listings
            </Typography>
            <Typography type="h5">
              €20 each<br></br>3 months
            </Typography>
            <Label>Billed €40 - 180 </Label>
            <Button style={{ fontSize: 12 }} fullWidth>
              SIGN UP
            </Button>
          </div>
          <div className={s.pricingCard}>
            <Typography type="h5" weight={800}>
              Up to 24 Listings
            </Typography>
            <Typography type="h5">
              €10 each<br></br>3 months
            </Typography>
            <Label>Billed €100 - 240 </Label>
            <Button style={{ fontSize: 12 }} fullWidth>
              SIGN UP
            </Button>
          </div>
          <div className={s.pricingCard}>
            <Typography type="h5" weight={800}>
              25 + ?
            </Typography>
            <Typography type="h5">Contact Us</Typography>
            <Label></Label>
            <Button style={{ fontSize: 12 }} fullWidth>
              EMAIL
            </Button>
          </div>
        </div>
        <Typography type="h6">Need more? Send us some mail on hello@onthatstreet.com</Typography>
      </div>
    </div>
  );
};
export default Pricing;
